import { Helmet } from 'react-helmet';
import HeaderNav from '../webLayout/HeaderNav';
import { Box, Divider, Grid, List, ListItemButton, ListItemText, Paper, Tooltip, Typography } from '@mui/material';
import BAButton from '../components/BAButton';
import { useState, useRef } from 'react';
import Footer from '../webLayout/footer';
import BACopyButton from '../components/BACopyButton';
import SearchIcon from '@mui/icons-material/Search';
import CodeIcon from '@mui/icons-material/Code';
import BAContent from '../components/BAContent';
import BAGrid from '../components/BAGrid';
import { rncButtonCode, rncHeadingCode, rncInputCode, rncLoaderCode, rncSwitchSelectCode, rncTextareaCode } from '../config/showCode';
import BAMobileView from '../components/BAMobileView';
import rncbuttonimage from '../assets/images/rncbutton.png'
import rncswitchselect from '../assets/images/rncswitchselect.png'
import rncinput from '../assets/images/rncinput.png'
import rncloader from '../assets/images/rncloader.png'
import rncloadersmall from '../assets/images/rncloadersmall.png'
import rncheadingImage from '../assets/images/rncheading.png'
import rnctextareaImage from '../assets/images/rnctextarea.png'




export default function ComponentsDocs() {
    const [openModal, setOpenModal] = useState(false)
    const RNCButtonContent = useRef();
    const RNCSwitchSelectContent = useRef();
    const RNCInputContent = useRef();
    const RNCRadioContent = useRef();
    const RNCHeadingContent = useRef();
    const RNCTextareaContent = useRef();
    const RNCLoaderContent = useRef();
    const RNCSmallLoaderContent = useRef();

    const installation = useRef()

    const [buttonList, setButtonsList] = useState([
        {
            name: 'RNCButton',
            refer: 'RNCButtonContent'
        },
        {
            name: 'RNCSwitchSelect',
            refer: 'RNCSwitchSelectContent'
        },
        {
            name: 'RNCInput',
            refer: 'RNCInputContent'
        },
        // {
        //     name: 'RNCRadio',
        //     refer: 'RNCRadioContent'
        // },
        {
            name: 'RNCHeading',
            refer: 'RNCHeadingContent'
        },
        {
            name: 'RNCTextarea',
            refer: 'RNCTextareaContent'
        },
        {
            name: 'RNCLoader',
            refer: 'RNCLoaderContent'
        },
        {
            name: 'RNCSmallLoader',
            refer: 'RNCSmallLoaderContent'
        },
    ])
    const [searchVal, setSearchVal] = useState('')
    const [selectedName, setSelectedName] = useState('')
    const [filteredList, setFilteredList] = useState([])
    const [installer, setInstaller] = useState('npm')


    const handleClick = (refer) => {
        switch (refer) {
            case "RNCButtonContent":
                RNCButtonContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "RNCSwitchSelectContent":
                RNCSwitchSelectContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "RNCInputContent":
                RNCInputContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "RNCRadioContent":
                RNCRadioContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "RNCHeadingContent":
                RNCHeadingContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "RNCTextareaContent":
                RNCTextareaContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "RNCLoaderContent":
                RNCLoaderContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "RNCSmallLoaderContent":
                RNCSmallLoaderContent.current?.scrollIntoView({ behavior: 'smooth' });
                break

        }
    };

    const instalDocs = () => {
        installation.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return <>
        <Helmet>
            <meta property="og:title" content={"RNC Styles | Docs"} />
            <meta property="og:description" content={"Create stunning mobile user interfaces with ease using RNC Styles – the ultimate solution for React Native developers. Say goodbye to manual styling and hello to a world of pre-built utility styles, all inspired by the best in web design."} />
            <meta property="og:image" content={"https://rncstyles.com/static/media/logo.859b6eba4e978fb4417b.png"} />
            <meta property="og:url" content={"https://rncstyles.com"} />
        </Helmet>
        <HeaderNav />
        <Box className='bgLight'>
            <Box className='px-3'>
                <Grid container>
                    <Grid item xs={12} sm={12} md={2}>
                        <Box className='p-2 sticky-top h-100vh overflow-auto'>
                            {/* <Box className='text-center p-2'>
                                <Tooltip title='Search Your Component'>
                                    <BAButton onClick={() => setOpenModal(true)} startIcon={<SearchIcon />} label="Search" />
                                </Tooltip>
                            </Box> */}
                            <List component="nav" aria-label="secondary mailbox folder">
                                <ListItemButton onClick={instalDocs}
                                >
                                    <ListItemText primary={"Installation"} />
                                </ListItemButton>
                            </List>
                            <Divider />
                            <Typography className="text-primary mt-2 p-3 fw-bold" ><CodeIcon /> Components</Typography>
                            <List component="nav" aria-label="secondary mailbox folder">
                                {buttonList.map((x, i) => {
                                    return <ListItemButton selected={x.name == selectedName} onClick={() => {
                                        setSelectedName(x.name)
                                        handleClick(x.refer)
                                    }} key={i}
                                    >
                                        <ListItemText primary={x.name} />
                                    </ListItemButton>
                                })}
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <Box className='p-3'>
                            <Paper ref={installation} className='p-3 bg-white'>
                                <Box className='py-2 mb-3'>
                                    <Typography className="fs-2 fw-bold">Installation</Typography>
                                    <Typography>Install RNC Styles, the easy and  complete React Native Styling.
                                    </Typography>
                                </Box>
                                <Box className='py-2'>
                                    <Typography className="fs-3 fw-bold">Default Installation</Typography>
                                    <Typography>Run this commands to add RNC Styles to your project:
                                    </Typography>
                                    <Box className='mt-4'>
                                        <BAButton variant={installer !== 'npm' ? 'text' : 'contained'} onClick={() => setInstaller('npm')} label='npm' />
                                        <BAButton variant={installer !== 'yarn' ? 'text' : 'contained'} onClick={() => setInstaller('yarn')} label='yarn' />
                                    </Box>
                                    <pre className="p-2 my-3 bg-dark text-white fs-5 rounded shadow">
                                        <BACopyButton code={`${installer === 'npm' ? 'npm i' : 'yarn add'} rncstyles`} />
                                        <code>
                                            {installer === 'npm' ? 'npm i' : 'yarn add'} rncstyles
                                        </code>
                                    </pre>
                                </Box>
                            </Paper>
                            <Box ref={RNCButtonContent}>
                                <BAContent heading='RNCButton' gridSection={<BAGrid datasource={[
                                    {
                                        name: 'label',
                                        type: 'string',
                                        default: ''
                                    },
                                    {
                                        name: 'onPress',
                                        type: '()=>{}',
                                        default: null
                                    },
                                    {
                                        name: 'loading',
                                        type: 'boolean',
                                        default: 'false'
                                    },
                                    {
                                        name: 'color',
                                        type: "primary | info | error | success | black | string",
                                        default: 'primary'
                                    },
                                    {
                                        name: 'startIcon',
                                        type: "string",
                                        default: null
                                    },
                                    {
                                        name: 'disabled',
                                        type: "boolean",
                                        default: 'false'
                                    },
                                    {
                                        name: 'customStyles',
                                        type: "object",
                                        default: null
                                    },
                                ]} gridCols={[
                                    {
                                        key: 'name',
                                        label: "Name"
                                    },
                                    {
                                        key: 'type',
                                        label: "Type"
                                    },
                                    {
                                        key: 'default',
                                        label: "Default"
                                    },
                                ]}
                                />
                                } codeSection={<>
                                    <Box className='px-2'>
                                        <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                                            <BACopyButton code={rncButtonCode} />
                                            <code>
                                                {rncButtonCode}
                                            </code>
                                        </pre>
                                    </Box>
                                </>} mobileSection={<>
                                    <BAMobileView>
                                        <img src={rncbuttonimage} width={'100%'} alt='' />
                                    </BAMobileView>
                                </>} />
                            </Box>
                            <Box ref={RNCSwitchSelectContent}>
                                <BAContent heading='RNCSwitchSelect' gridSection={<BAGrid datasource={[
                                    {
                                        name: 'options',
                                        type: `{
                                            label: string,
                                            value: string
                                        }[]`,
                                        default: ''
                                    },
                                    {
                                        name: 'onChange',
                                        type: '(e)=>{}',
                                        default: null
                                    },
                                    {
                                        name: 'color',
                                        type: "primary | info | error | success | black | string",
                                        default: 'primary'
                                    },
                                ]} gridCols={[
                                    {
                                        key: 'name',
                                        label: "Name"
                                    },
                                    {
                                        key: 'type',
                                        label: "Type"
                                    },
                                    {
                                        key: 'default',
                                        label: "Default"
                                    },
                                ]}
                                />
                                } codeSection={<>
                                    <Box className='px-2'>
                                        <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                                            <BACopyButton code={rncSwitchSelectCode} />
                                            <code>
                                                {rncSwitchSelectCode}
                                            </code>
                                        </pre>
                                    </Box>
                                </>} mobileSection={<>
                                    <BAMobileView>
                                        <img src={rncswitchselect} width={'100%'} alt='' />
                                    </BAMobileView>
                                </>} />
                            </Box>
                            <Box ref={RNCInputContent}>
                                <BAContent heading='RNCInput' gridSection={<BAGrid datasource={[
                                    {
                                        name: 'label',
                                        type: 'string',
                                        default: ''
                                    },
                                    {
                                        name: 'placeholder',
                                        type: 'string',
                                        default: ''
                                    },
                                    {
                                        name: 'onChangeText',
                                        type: '(e)=>{}',
                                        default: null
                                    },
                                    {
                                        name: 'color',
                                        type: "primary | info | error | success | black | string",
                                        default: 'primary'
                                    },
                                    {
                                        name: 'icon',
                                        type: "string",
                                        default: null
                                    },
                                    {
                                        name: 'value',
                                        type: "any",
                                        default: ''
                                    },
                                    {
                                        name: 'disabled',
                                        type: "boolean",
                                        default: 'false'
                                    },
                                    {
                                        name: 'required',
                                        type: "boolean",
                                        default: 'false'
                                    },
                                    {
                                        name: 'secureTextEntry',
                                        type: "boolean",
                                        default: 'false'
                                    },
                                    {
                                        name: 'customStyles',
                                        type: "object",
                                        default: null
                                    },
                                    {
                                        name: 'keyboardType',
                                        type: `"default" | 'numeric' | 'email-address' | "ascii-capable" | 'numbers-and-punctuation' | 'url' | 'number-pad' | 'phone-pad' | 'name-phone-pad' | 'decimal-pad' | 'twitter' | 'web-search' | 'visible-password'`,
                                        default: null
                                    },
                                ]} gridCols={[
                                    {
                                        key: 'name',
                                        label: "Name"
                                    },
                                    {
                                        key: 'type',
                                        label: "Type"
                                    },
                                    {
                                        key: 'default',
                                        label: "Default"
                                    },
                                ]}
                                />
                                } codeSection={<>
                                    <Box className='px-2'>
                                        <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                                            <BACopyButton code={rncInputCode} />
                                            <code>
                                                {rncInputCode}
                                            </code>
                                        </pre>
                                    </Box>
                                </>} mobileSection={<>
                                    <BAMobileView>
                                        <img src={rncinput} width={'100%'} alt='' />
                                    </BAMobileView>
                                </>} />
                            </Box>
                            <Box ref={RNCLoaderContent}>
                                <BAContent heading='RNCLoader' gridSection={<BAGrid datasource={[
                                    {
                                        name: 'open',
                                        type: 'boolean',
                                        default: 'false'
                                    },
                                    {
                                        name: 'color',
                                        type: "primary | info | error | success | black | string",
                                        default: 'primary'
                                    },
                                ]} gridCols={[
                                    {
                                        key: 'name',
                                        label: "Name"
                                    },
                                    {
                                        key: 'type',
                                        label: "Type"
                                    },
                                    {
                                        key: 'default',
                                        label: "Default"
                                    },
                                ]}
                                />
                                } codeSection={<>
                                    <Box className='px-2'>
                                        <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                                            <BACopyButton code={rncLoaderCode} />
                                            <code>
                                                {rncLoaderCode}
                                            </code>
                                        </pre>
                                    </Box>
                                </>} mobileSection={<>
                                    <BAMobileView>
                                        <img src={rncloader} width={'100%'} alt='' />
                                    </BAMobileView>
                                </>} />
                            </Box>
                            <Box ref={RNCSmallLoaderContent}>
                                <BAContent heading='RNCLoaderSmall' gridSection={<BAGrid datasource={[
                                    {
                                        name: 'color',
                                        type: "primary | info | error | success | black | string",
                                        default: 'primary'
                                    },
                                ]} gridCols={[
                                    {
                                        key: 'name',
                                        label: "Name"
                                    },
                                    {
                                        key: 'type',
                                        label: "Type"
                                    },
                                    {
                                        key: 'default',
                                        label: "Default"
                                    },
                                ]}
                                />
                                } codeSection={<>
                                    <Box className='px-2'>
                                        <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                                            <BACopyButton code={rncLoaderCode} />
                                            <code>
                                                {rncLoaderCode}
                                            </code>
                                        </pre>
                                    </Box>
                                </>} mobileSection={<>
                                    <BAMobileView>
                                        <img src={rncloadersmall} width={'100%'} alt='' />
                                    </BAMobileView>
                                </>} />
                            </Box>
                            <Box ref={RNCHeadingContent}>
                                <BAContent heading='RNCHeading' gridSection={<BAGrid datasource={[
                                    {
                                        name: 'title',
                                        type: 'string',
                                        default: ''
                                    },
                                    {
                                        name: 'subTitle',
                                        type: 'string',
                                        default: ''
                                    },
                                    {
                                        name: 'iconButton',
                                        type: 'node',
                                        default: ''
                                    },
                                ]} gridCols={[
                                    {
                                        key: 'name',
                                        label: "Name"
                                    },
                                    {
                                        key: 'type',
                                        label: "Type"
                                    },
                                    {
                                        key: 'default',
                                        label: "Default"
                                    },
                                ]}
                                />
                                } codeSection={<>
                                    <Box className='px-2'>
                                        <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                                            <BACopyButton code={rncHeadingCode} />
                                            <code>
                                                {rncHeadingCode}
                                            </code>
                                        </pre>
                                    </Box>
                                </>} mobileSection={<>
                                    <BAMobileView>
                                        <img src={rncheadingImage} width={'100%'} alt='' />
                                    </BAMobileView>
                                </>} />
                            </Box>
                            <Box ref={RNCTextareaContent}>
                                <BAContent heading='RNCTextarea' gridSection={<BAGrid datasource={[
                                    {
                                        name: 'label',
                                        type: 'string',
                                        default: ''
                                    },
                                    {
                                        name: 'placeholder',
                                        type: 'string',
                                        default: ''
                                    },
                                    {
                                        name: 'onChangeText',
                                        type: '(e)=>{}',
                                        default: null
                                    },
                                    {
                                        name: 'color',
                                        type: "primary | info | error | success | black | string",
                                        default: 'primary'
                                    },
                                    {
                                        name: 'icon',
                                        type: "string",
                                        default: null
                                    },
                                    {
                                        name: 'value',
                                        type: "any",
                                        default: ''
                                    },
                                    {
                                        name: 'disabled',
                                        type: "boolean",
                                        default: 'false'
                                    },
                                    {
                                        name: 'required',
                                        type: "boolean",
                                        default: 'false'
                                    },
                                ]} gridCols={[
                                    {
                                        key: 'name',
                                        label: "Name"
                                    },
                                    {
                                        key: 'type',
                                        label: "Type"
                                    },
                                    {
                                        key: 'default',
                                        label: "Default"
                                    },
                                ]}
                                />
                                } codeSection={<>
                                    <Box className='px-2'>
                                        <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                                            <BACopyButton code={rncTextareaCode} />
                                            <code>
                                                {rncTextareaCode}
                                            </code>
                                        </pre>
                                    </Box>
                                </>} mobileSection={<>
                                    <BAMobileView>
                                        <img src={rnctextareaImage} width={'100%'} alt='' />
                                    </BAMobileView>
                                </>} />
                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Footer />
    </>
}