import { Box, Typography } from "@mui/material";
import BAContent from "../components/BAContent";
import BAGrid from "../components/BAGrid";
import BAMobileView from "../components/BAMobileView";
import { useState } from "react";
import { Link } from "react-router-dom";
import styling from "../rnTheme/rncstyles";
import { linkCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";


export default function BALink() {
    const [link, setLink] = useState([
        {
            name: 'link',
            value: `color: styling._info;
            fontSize: 18;`
        },

    ])
    return (
        <>
            <BAContent
                heading={"Link"}
                gridSection={<BAGrid datasource={link} gridCols={[
                    {
                        key: 'name',
                        label: "Name"
                    },
                    {
                        key: 'value',
                        label: "Value"
                    },
                ]} />}
                codeSection={<Box className='px-2'>
                    <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                        <BACopyButton code={linkCode} />
                        <code>
                            {linkCode}
                        </code>
                    </pre>
                </Box>}
                mobileSection={
                    <BAMobileView>
                        <Box>
                            <Typography className="p-2" variant="h4">
                                link
                            </Typography>
                            <Box className="p-2 text-center">
                                <Link sx={{
                                    color: styling._info,
                                    fontSize: 18,
                                }}>Click here for Details</Link>
                            </Box>
                        </Box>
                    </BAMobileView>} />
        </>
    )
}