import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import BAMobileView from "../components/BAMobileView";
import BAContent from "../components/BAContent";
import BAShowcolor from "../components/BAShowcolor";
import BAGrid from "../components/BAGrid";
import styling from "../rnTheme/rncstyles";
import { widthCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";

function MainBox({ title, width }) {
  return (
    <>
      <Box className="my-1 align-items-center">
        <Typography className="p-2" variant="h5">
          {title}
        </Typography>
        <Box
          width={width}
          className="shadow rounded"
          sx={{ height: 25, backgroundColor: styling._dark }}
        ></Box>
      </Box>
    </>
  );
}

export default function BAWidth() {
  const [width, setWidth] = useState([
    {
      name: "w10",
      value: JSON.stringify(styling.w10),
    },
    {
      name: "w20",
      value: JSON.stringify(styling.w20),
    },
    {
      name: "w25",
      value: JSON.stringify(styling.w25),
    },
    {
      name: "w30",
      value: JSON.stringify(styling.w30),
    },
    {
      name: "w40",
      value: JSON.stringify(styling.w40),
    },
    {
      name: "w50",
      value: JSON.stringify(styling.w50),
    },
    {
      name: "w60",
      value: JSON.stringify(styling.w60),
    },
    {
      name: "w70",
      value: JSON.stringify(styling.w70),
    },
    {
      name: "w75",
      value: JSON.stringify(styling.w75),
    },
    {
      name: "w80",
      value: JSON.stringify(styling.w80),
    },
    {
      name: "w90",
      value: JSON.stringify(styling.w90),
    },
    {
      name: "w100",
      value: JSON.stringify(styling.w100),
    },
  ]);
  return (
    <>
      <BAContent
        heading="Width"
        gridSection={
          <BAGrid
            datasource={width}
            gridCols={[
              {
                key: "name",
                label: "Name",
              },
              {
                key: "value",
                label: "value",
              },
            ]}
          />
        }
        codeSection={
          <Box className="px-2" sx={{ height: 700 }}>
            <pre className="bg-dark h-100 text-white p-2 shadow rounded">
              <BACopyButton code={widthCode} />
              <code>
                {widthCode}
              </code>
            </pre>
          </Box>
        }
        mobileSection={
          <BAMobileView>
            <MainBox title="w10" width={styling.w10} />
            <MainBox title="w20" width={styling.w20} />
            <MainBox title="w25" width={styling.w25} />
            <MainBox title="w30" width={styling.w30} />
            <MainBox title="w40" width={styling.w40} />
            <MainBox title="w50" width={styling.w50} />
            <MainBox title="w60" width={styling.w60} />
            <MainBox title="w70" width={styling.w70} />
            <MainBox title="w75" width={styling.w75} />
            <MainBox title="w80" width={styling.w80} />
            <MainBox title="w90" width={styling.w90} />
            <MainBox title="w10" width={styling.w100} />
          </BAMobileView>
        }
      />
    </>
  );
}
