import { Box, Button, Grid, Paper, Tooltip, Typography } from "@mui/material";
import '../App.css'
import BAIconbutton from "./BAIconbutton";
import { useState } from "react";
import BAButton from "./BAButton";
import CodeIcon from '@mui/icons-material/Code';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import Chip from '@mui/material/Chip';

export default function BAContent({ heading, children, gridSection, codeSection, mobileSection }) {
    const [showCode, setshowCode] = useState(false);
    return <>
        <Box className='py-2'>
            <Typography variant="h4">{heading}</Typography>
            <Box className='py-3'>
                <Paper className="p-3">
                    <Grid container>
                        <Grid xs={12} sm={12} item md={8}>
                            <Box sx={{ maxHeight: 700, overflow: 'auto' }}>
                                {gridSection}
                            </Box>
                            <Box sx={{ my: 3 }}>
                                <Chip
                                    label={showCode ? "Hide Code" : "Show Code"}
                                    onClick={() => setshowCode(!showCode)}
                                    onDelete={() => setshowCode(!showCode)}
                                    deleteIcon={showCode ? <CodeOffIcon /> : <CodeIcon />}
                                    variant="outlined"
                                />
                            </Box>
                            {showCode && <Box className='w-100 overflow-auto'>
                                {codeSection}
                            </Box>}
                        </Grid>
                        <Grid xs={12} sm={12} item md={4}>
                            <Box className='w-100 overflow-auto'>
                                {mobileSection}
                            </Box>
                        </Grid>
                    </Grid>
                    {children}
                </Paper>
            </Box>
        </Box >
    </>
}