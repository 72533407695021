const colorCode = `
import rncstyles,{_dark,_secondary,_light,_success,_danger,_info,_black,_white} from 'rncstyles'
`
export const rncButtonCode = `
import { View } from 'react-native';
import { RNCButton } from 'rncstyles';

function HomeScreen() {
    return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <RNCButton customStyles={{ margin: 5 }} label='Default' />
            <RNCButton customStyles={{ margin: 5 }} color='primary' label='Primary' />
            <RNCButton customStyles={{ margin: 5 }} color='success' label='Success' />
            <RNCButton customStyles={{ margin: 5 }} color='error' label='Error' />
            <RNCButton customStyles={{ margin: 5 }} color='info' label='Info' />
            <RNCButton customStyles={{ margin: 5 }} color='black' label='Black' />
            <RNCButton customStyles={{ margin: 5 }} color='purple' label='Custom Color' />
        </View>
    );
}

`
export const rncInputCode = `
import { View } from 'react-native';
import rncStyles, { RNCInput } from 'rncstyles';

function HomeScreen() {
    return (
        <View style={[
            rncStyles.h100,
            rncStyles.flexColumn,
            rncStyles.justifyContentCenter,
            rncStyles.p2
        ]}>
            <RNCInput customStyles={{ marginBottom: 20 }} placeholder='Testing Placeholder' label='Text Input' />
            <RNCInput customStyles={{ marginBottom: 20 }} placeholder='Testing Placeholder' disabled={true} label='Text Input' />
            <RNCInput customStyles={{ marginBottom: 20 }} placeholder='Testing Placeholder' disabled={true} label='Text Input' />
            <RNCInput customStyles={{ marginBottom: 20 }} color="error" placeholder='Testing Placeholder' disabled={true} label='Text Input' />
        </View>
    );
}

`
export const rncTextareaCode = `
import { View } from 'react-native';
import rncStyles, { RNCTextarea } from 'rncstyles';

function HomeScreen() {
    return (
        <View style={[
            rncStyles.h100,
            rncStyles.p2
        ]}>
            <RNCTextarea label='Textarea' color='primary' />
        </View>
    );
}
`
export const rncSwitchSelectCode = `
import * as React from 'react';
import { View } from 'react-native';
import { RNCSwitchSelect } from 'rncstyles';

function HomeScreen() {
    return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <RNCSwitchSelect onChange={(e) => {
                console.log(e)
            }} options={[
                {
                    label: 'Tab 1',
                    value: 'Tab 1'
                },
                {
                    label: 'Tab 2',
                    value: 'Tab 2'
                },
                {
                    label: 'Tab 3',
                    value: 'Tab 3'
                },
            ]} />

            <RNCSwitchSelect color='info' onChange={(e) => {
                console.log(e)
            }} options={[
                {
                    label: 'Tab 1',
                    value: 'Tab 1'
                },
                {
                    label: 'Tab 2',
                    value: 'Tab 2'
                },
                {
                    label: 'Tab 3',
                    value: 'Tab 3'
                },
            ]} />
            <RNCSwitchSelect color='purple' onChange={(e) => {
                console.log(e)
            }} options={[
                {
                    label: 'Tab 1',
                    value: 'Tab 1'
                },
                {
                    label: 'Tab 2',
                    value: 'Tab 2'
                },
                {
                    label: 'Tab 3',
                    value: 'Tab 3'
                },
            ]} />
        </View>
    );
}

`
export const rncLoaderCode = `
import { View } from 'react-native';
import rncStyles, { RNCLoader } from 'rncstyles';

function HomeScreen() {
    return (
        <View style={[
            rncStyles.h100,
            rncStyles.flexColumn,
            rncStyles.justifyContentCenter,
            rncStyles.p2
        ]}>
            <RNCLoader color='info' open={true} />
        </View>
    );
}
`
export const rncLoaderSmallCode = `
import { View } from 'react-native';
import rncStyles, { RNCLoaderSmall } from 'rncstyles';

function HomeScreen() {
    return (
        <View style={[
            rncStyles.h100,
            rncStyles.p2
        ]}>
            <RNCLoaderSmall color='error' />
            <RNCLoaderSmall color='info' />
            <RNCLoaderSmall color='purple' />
        </View>
    );
}
`
export const rncHeadingCode = `
import rncStyles, { RNCHeading } from 'rncstyles';
import BAButton from '../testingComponents/BAButton';

function HomeScreen() {
    return (
        <View style={[
            rncStyles.h100,
            rncStyles.p2
        ]}>
            <RNCHeading
                title='Heading'
                subTitle='This is Sub Heading'
                iconButton={<>
                    <BAButton label='Save' />
                </>}
            />
        </View>
    );
}
`


const textColorCode =
  `
import { Text, View } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
        <View style={rncStyles.p2}>
            <Text style={[rncStyles.textPrimary, rncStyles.fs1]}>name : textPrimary</Text>
            <Text style={[rncStyles.textSecondary, rncStyles.fs1]}>name : textSecondary</Text>
            <Text style={[rncStyles.textSuccess, rncStyles.fs1]}>name : textSuccess</Text>
            <Text style={[rncStyles.textDanger, rncStyles.fs1]}>name : textDanger</Text>
            <Text style={[rncStyles.textInfo, rncStyles.fs1]}>name : textInfo</Text>
            <Text style={[rncStyles.textWhite, rncStyles.fs1, rncStyles.bgBlack]}>name : textWhite</Text>
        </View>
    </>
	)
};
`

const backgroundColorCode =
  `
import { Text, View } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
        <View style={[rncStyles.p2, rncStyles.h100, rncStyles.bgThemeLight]}>
            <View style={[rncStyles.flexRow, rncStyles.h10]}>
                <View style={[rncStyles.h100, rncStyles.w20, rncStyles.bgPrimary]}></View>
                <Text style={[rncStyles.textBlack, rncStyles.mt2, rncStyles.ms1, rncStyles.fs5]}>bgPrimary</Text>
            </View>
            <View style={[rncStyles.flexRow, rncStyles.h10]}>
                <View style={[rncStyles.h100, rncStyles.w20, rncStyles.bgSecondary]}></View>
                <Text style={[rncStyles.textBlack, rncStyles.mt2, rncStyles.ms1, rncStyles.fs5]}>bgSecondary</Text>
            </View>
            <View style={[rncStyles.flexRow, rncStyles.h10]}>
                <View style={[rncStyles.h100, rncStyles.w20, rncStyles.bgSuccess]}></View>
                <Text style={[rncStyles.textBlack, rncStyles.mt2, rncStyles.ms1, rncStyles.fs5]}>bgSuccess</Text>
            </View>
            <View style={[rncStyles.flexRow, rncStyles.h10]}>
                <View style={[rncStyles.h100, rncStyles.w20, rncStyles.bgInfo]}></View>
                <Text style={[rncStyles.textBlack, rncStyles.mt2, rncStyles.ms1, rncStyles.fs5]}>bgInfo</Text>
            </View>
            <View style={[rncStyles.flexRow, rncStyles.h10]}>
                <View style={[rncStyles.h100, rncStyles.w20, rncStyles.bgDanger]}></View>
                <Text style={[rncStyles.textBlack, rncStyles.mt2, rncStyles.ms1, rncStyles.fs5]}>bgDanger</Text>
            </View>
            <View style={[rncStyles.flexRow, rncStyles.h10]}>
                <View style={[rncStyles.h100, rncStyles.w20, rncStyles.bgBlack]}></View>
                <Text style={[rncStyles.textBlack, rncStyles.mt2, rncStyles.ms1, rncStyles.fs5]}>bgBlack</Text>
            </View>
            <View style={[rncStyles.flexRow, rncStyles.h10]}>
                <View style={[rncStyles.h100, rncStyles.w20, rncStyles.bgWhite]}></View>
                <Text style={[rncStyles.textBlack, rncStyles.mt2, rncStyles.ms1, rncStyles.fs5]}>bgWhite</Text>
            </View>
        </View>
    </>
	)
};
`

const textAllignmentCode =
  `
import { Text, View } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
    <View style={[rncStyles.p2, rncStyles.bgWhite, rncStyles.h100]}>
        <Text style={[rncStyles.fs1, rncStyles.textBlack]}>Alignment</Text>
        <View style={[rncStyles.bgTransparent, rncStyles.my1]}>
            <Text style={[rncStyles.textBlack, rncStyles.textStart, rncStyles.fs2]}>textleft</Text>
        </View>
        <View style={[rncStyles.bgTransparent, rncStyles.my1]}>
            <Text style={[rncStyles.textBlack, rncStyles.textCenter, rncStyles.fs2]}>textcenter</Text>
        </View>
        <View style={[rncStyles.bgTransparent, rncStyles.my1]}>
            <Text style={[rncStyles.textBlack, rncStyles.textEnd, rncStyles.fs2]}>textright</Text>
        </View>
        <View style={[rncStyles.bgTransparent, rncStyles.my1]}>
            <Text style={[rncStyles.textBlack, rncStyles.textBold, rncStyles.fs2]}>Bold:textbold</Text>
        </View>
        <View style={[rncStyles.bgTransparent, rncStyles.my1]}>
            <Text style={[rncStyles.fs2, rncStyles.textBlack, rncStyles.textUnderline]}>Underline:textunderline</Text>
        </View>
  </View>
    </>
	)
};
`

const fontSizeCode =
  `
import { Text, View } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
    <View style={[rncStyles.p2, rncStyles.bgWhite, rncStyles.h100]}>
        <Text style={[rncStyles.fsXL, rncStyles.textBlack]}>fsXL</Text>
        <Text style={[rncStyles.fsL, rncStyles.textBlack]}>fsL</Text>
        <Text style={[rncStyles.fs1, rncStyles.textBlack]}>fs1</Text>
        <Text style={[rncStyles.fs2, rncStyles.textBlack]}>fs2</Text>
        <Text style={[rncStyles.fs3, rncStyles.textBlack]}>fs3</Text>
        <Text style={[rncStyles.fs4, rncStyles.textBlack]}>fs4</Text>
        <Text style={[rncStyles.fs5, rncStyles.textBlack]}>fs5</Text>
        <Text style={[rncStyles.fs6, rncStyles.textBlack]}>fs6</Text>
  </View>
    </>
	)
};
`

const paddingCode =
  `
import { Text, View, ScrollView } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
    <View style={[rncStyles.h100, rncStyles.bgWhite]}>
     <ScrollView>
      <View style={[rncStyles.h100, rncStyles.bgLight, rncStyles.p2]}>
        <View style={{ height: 300 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>p1</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.p1]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>p2</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.p2]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>p3</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.p3]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>p4</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.p4]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>p5</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.p5]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>px1</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.px1]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>px2</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.px2]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>px3</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.px3]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>px4</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.px4]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>px5</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.px5]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>py1</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.py1]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>py2</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.py2]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>py3</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.py3]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>py4</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.py4]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>py5</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.py5]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>ps1</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.ps1]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>ps2</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.ps2]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>ps3</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.ps3]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>ps4</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.ps4]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>ps5</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.ps5]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pe1</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pe1]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pe2</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pe2]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pe3</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pe3]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pe4</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pe4]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pe5</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pe5]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pt1</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pt1]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pt2</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pt2]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pt3</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pt3]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pt4</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pt4]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pt5</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pt5]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pb1</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pb1]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pb2</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pb2]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pb3</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pb3]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pb4</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pb4]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>pb5</Text>
          <View style={[rncStyles.border1, rncStyles.h50, rncStyles.bgInfo, rncStyles.pb5]}>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
          </View>
        </View>
      </View>
     </ScrollView>
    </View>
    </>
	)
};
`

const marginCode =
  `
import { Text, View, ScrollView } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
    <View style={[rncStyles.h100, rncStyles.bgWhite]}>
    <ScrollView>
      <View style={[rncStyles.h100, rncStyles.bgLight, rncStyles.p2]}>
        <View style={{ height: 300 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>m1</Text>

          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.m1]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>m2</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.m2]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>m3</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.m3]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>m4</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.m4]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>m5</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.m5]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mx1</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mx1]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mx2</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mx2]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mx3</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mx3]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mx4</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mx4]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mx5</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mx5]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>my1</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.my1]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>my2</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.my2]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>my3</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.my3]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>my4</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.my4]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>my5</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.my5]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>ms1</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.ms1]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>ms2</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.ms2]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>ms3</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.ms3]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>ms4</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.ms4]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>ms5</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.ms5]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>me1</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.me1]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>me2</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.me2]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>me3</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.me3]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>me4</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.me4]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>me5</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.me5]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mt1</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mt1]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mt2</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mt2]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mt3</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mt3]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mt4</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mt4]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mt5</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mt5]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mb1</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mb1]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mb2</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mb2]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mb3</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mb3]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mb4</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mb4]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
        <View style={{ height: 300, marginTop: -80 }}>
          <Text style={[rncStyles.fs2, rncStyles.textBlack]}>mb5</Text>
          <View style={[rncStyles.bgPrimary]}>
            <View style={[rncStyles.border1, rncStyles.h50, rncStyles.mb5]}>
              <View style={[rncStyles.h100, rncStyles.bgWhite]}></View>
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  </View>       
    </>
	)
};
`

const borderCode =
  `
import { Text, View, ScrollView } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
  <ScrollView>
    <View style={[rncStyles.bgWhite, rncStyles.h100, rncStyles.p2]}>
      <Text style={[rncStyles.fs2, rncStyles.textBlack]}>Borders</Text>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>border1</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>border2</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border2]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>border3</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border3]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>border4</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border4]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>border5</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border5]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTop1</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderTop1]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTop2</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderTop2]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTop3</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderTop3]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTop4</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderTop4]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTop5</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderTop5]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottom1</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderBottom1]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottom2</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderBottom2]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottom3</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderBottom3]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottom4</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderBottom4]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottom5</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderBottom5]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRight1</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderRight1]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRight2</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderRight2]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRight3</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderRight3]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRight4</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderRight4]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRight5</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderRight5]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeft1</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderLeft1]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeft2</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderLeft2]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeft3</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderLeft3]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeft4</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderLeft4]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeft5</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.borderLeft5]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderWhite</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderWhite]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderPrimary</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderPrimary]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderSecondary</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderSecondary]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderSuccess</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderSuccess]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderDanger</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderDanger]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderWarning</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderWarning]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBlack</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderBlack]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderInfo</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderInfo]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLight</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderLight]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTopWhite</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderTopWhite]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTopPrimary</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderTopPrimary]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTopSecondary</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderTopSecondary]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTopSuccess</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderTopSuccess]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTopDanger</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderTopDanger]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTopWarning</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderTopWarning]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTopBlack</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderTopBlack]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTopInfo</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderTopInfo]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderTopLight</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderTopLight]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottomWhite</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderBottomWhite]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottomPrimary</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderBottomPrimary]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottomSecondary</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderBottomSecondary]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottomSuccess</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderBottomSuccess]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottomDanger</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderBottomDanger]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottomWarning</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderBottomWarning]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottomBlack</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderBottomBlack]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottomInfo</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderBottomInfo]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderBottomLight</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderBottomLight]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeftWhite</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderLeftWhite]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeftPrimary</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderLeftPrimary]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeftSecondary</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderLeftSecondary]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeftSuccess</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderLeftSuccess]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeftDanger</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderLeftDanger]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeftWarning</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderLeftWarning]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeftBlack</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderLeftBlack]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeftInfo</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderLeftInfo]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderLeftLight</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderLeftLight]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRightWhite</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderRightWhite]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRightPrimary</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderRightPrimary]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRightSecondary</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderRightSecondary]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRightSuccess</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderRightSuccess]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRightDanger</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderRightDanger]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRightWarning</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderRightWarning]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRightBlack</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderRightBlack]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRightInfo</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderRightInfo]}></View>
      <Text style={[rncStyles.fs4, rncStyles.textBlack]}>borderRightLight</Text>
      <View style={[{ height: 150 }, rncStyles.mb2, rncStyles.border1, rncStyles.borderRightLight]}></View>
    </View>
  </ScrollView>
    </>
	)
};
`

const widthCode =
  ` 
import { Text, View, ScrollView } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
    <View style={[rncStyles.h100]}>
    <ScrollView>
      <View style={[rncStyles.bgWhite]}>
        <View style={[{ height: 100 }]}>
          <Text style={[rncStyles.textBlack, rncStyles.fs3]}>w10</Text>
          <View style={[rncStyles.bgPrimary, rncStyles.h25, rncStyles.w10]}></View>
        </View>
        <View style={[{ height: 100 }]}>
          <Text style={[rncStyles.textBlack, rncStyles.fs3]}>w20</Text>
          <View style={[rncStyles.bgPrimary, rncStyles.h25, rncStyles.w20]}></View>
        </View>
        <View style={[{ height: 100 }]}>
          <Text style={[rncStyles.textBlack, rncStyles.fs3]}>w25</Text>
          <View style={[rncStyles.bgPrimary, rncStyles.h25, rncStyles.w25]}></View>
        </View>
        <View style={[{ height: 100 }]}>
          <Text style={[rncStyles.textBlack, rncStyles.fs3]}>w30</Text>
          <View style={[rncStyles.bgPrimary, rncStyles.h25, rncStyles.w30]}></View>
        </View>
        <View style={[{ height: 100 }]}>
          <Text style={[rncStyles.textBlack, rncStyles.fs3]}>w40</Text>
          <View style={[rncStyles.bgPrimary, rncStyles.h25, rncStyles.w40]}></View>
        </View>
        <View style={[{ height: 100 }]}>
          <Text style={[rncStyles.textBlack, rncStyles.fs3]}>w50</Text>
          <View style={[rncStyles.bgPrimary, rncStyles.h25, rncStyles.w50]}></View>
        </View>
        <View style={[{ height: 100 }]}>
          <Text style={[rncStyles.textBlack, rncStyles.fs3]}>w60</Text>
          <View style={[rncStyles.bgPrimary, rncStyles.h25, rncStyles.w60]}></View>
        </View>
        <View style={[{ height: 100 }]}>
          <Text style={[rncStyles.textBlack, rncStyles.fs3]}>w70</Text>
          <View style={[rncStyles.bgPrimary, rncStyles.h25, rncStyles.w70]}></View>
        </View>
        <View style={[{ height: 100 }]}>
          <Text style={[rncStyles.textBlack, rncStyles.fs3]}>w75</Text>
          <View style={[rncStyles.bgPrimary, rncStyles.h25, rncStyles.w75]}></View>
        </View>
        <View style={[{ height: 100 }]}>
          <Text style={[rncStyles.textBlack, rncStyles.fs3]}>w80</Text>
          <View style={[rncStyles.bgPrimary, rncStyles.h25, rncStyles.w80]}></View>
        </View>
        <View style={[{ height: 100 }]}>
          <Text style={[rncStyles.textBlack, rncStyles.fs3]}>w90</Text>
          <View style={[rncStyles.bgPrimary, rncStyles.h25, rncStyles.w90]}></View>
        </View>
        <View style={[{ height: 100 }]}>
          <Text style={[rncStyles.textBlack, rncStyles.fs3]}>w100</Text>
          <View style={[rncStyles.bgPrimary, rncStyles.h25, rncStyles.w100]}></View>
        </View>
      </View>
    </ScrollView>
  </View>        
    </>
	)
};
`

const buttonCode =
  `
import { Text, View, TouchableOpacity } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
        <>
            <View style={[rncStyles.h100, rncStyles.bgWhite]}>
                <TouchableOpacity style={[rncStyles.btn]}><Text>Click</Text></TouchableOpacity>
            </View>          
        </>
	)
};
`

const chipsCode =
  `
import { Text, View } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
    <View style={[rncStyles.bgWhite, rncStyles.h100]}>
        <Text style={[rncStyles.chips]}>ABC</Text>
    </View>
    </>
	)
};
`

const positionsCode =
  `
import { Text, View, ScrollView } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
    <View style={[rncStyles.bgWhite, rncStyles.h100, rncStyles.p2]}>
      <View style={{ height: 150,marginBottom:50}}>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>Position Relative</Text>
        <View style={[rncStyles.h90, rncStyles.w100, rncStyles.bgInfo,rncStyles.positionRelative]}>
          <Text style={[rncStyles.textWhite, rncStyles.fs3]}>Position Relative</Text>
        </View>
      </View>
      <View style={{ height: 150 }}>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>Position Absolute</Text>
        <View style={[rncStyles.h90, rncStyles.w100, rncStyles.bgInfo]}>
          <Text style={[rncStyles.textWhite, rncStyles.fs3]}>Position Relative</Text>
          <View style={[rncStyles.flexCenter,rncStyles.positionAbsolute]}>
            <Text style={[rncStyles.textWhite, rncStyles.fs3,rncStyles.bgBlack]}>Position Absolute</Text>
          </View>
        </View>
      </View>
    </View>
    </>
	)
};
`

const borderRadiusCode =
  `
import { Text, View, ScrollView } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
    <View style={[rncStyles.h100, rncStyles.bgWhite, rncStyles.p2]}>
    <ScrollView>
      <View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>Border Radius</Text>
        <View style={{ height: 150, marginBottom: 10 }}>
          <Text style={[rncStyles.textBlack, rncStyles.fs4]}>rounded</Text>
          <View style={[rncStyles.h90, rncStyles.bgPrimary, rncStyles.border5, rncStyles.w100, rncStyles.rounded]}></View>
        </View>
        <View style={{ height: 150, marginBottom: 10, }}>
          <Text style={[rncStyles.textBlack, rncStyles.fs4, rncStyles.mt3]}>roundedPill</Text>
          <View style={[rncStyles.h90, rncStyles.bgPrimary, rncStyles.border5, rncStyles.w100, rncStyles.roundedPill]}></View>
        </View>
        <View style={{ height: 150, marginBottom: 10 }}>
          <Text style={[rncStyles.textBlack, rncStyles.fs4, rncStyles.mt3]}>roundedTopRight</Text>
          <View style={[rncStyles.h90, rncStyles.bgPrimary, rncStyles.border5, rncStyles.w100, rncStyles.roundedTopRight]}></View>
        </View>
        <View style={{ height: 150, marginBottom: 10 }}>
          <Text style={[rncStyles.textBlack, rncStyles.fs4, rncStyles.mt3]}>roundedTopLeft</Text>
          <View style={[rncStyles.h90, rncStyles.bgPrimary, rncStyles.border5, rncStyles.w100, rncStyles.roundedTopLeft]}></View>
        </View>
        <View style={{ height: 150, marginBottom: 10 }}>
          <Text style={[rncStyles.textBlack, rncStyles.fs4, rncStyles.mt3]}>roundedBottomRight</Text>
          <View style={[rncStyles.h90, rncStyles.bgPrimary, rncStyles.border5, rncStyles.w100, rncStyles.roundedBottomRight]}></View>
        </View>
        <View style={{ height: 150, marginBottom: 10 }}>
          <Text style={[rncStyles.textBlack, rncStyles.fs4, rncStyles.mt3]}>roroundedBottomLeftundedTopRight</Text>
          <View style={[rncStyles.h90, rncStyles.bgPrimary, rncStyles.border5, rncStyles.w100, rncStyles.roundedBottomLeft]}></View>
        </View>
      </View>
    </ScrollView>
  </View>
    </> 
	)
};
`

const flexCode =
  `
import { Text, View } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
    <View style={[rncStyles.bgWhite, rncStyles.h100, rncStyles.p2]}>
    <ScrollView>
      <View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>FlexRow</Text>
        <View style={[{ height: 150 }, rncStyles.bgTransparent, rncStyles.mb1, rncStyles.border2, rncStyles.p2, rncStyles.flexRow]}>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgPrimary]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgDanger]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgSuccess]}></View>
        </View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>flexColumn</Text>
        <View style={[{ height: 150 }, rncStyles.bgTransparent, rncStyles.mb1, rncStyles.border2, rncStyles.p2, rncStyles.flexColumn]}>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgPrimary]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgDanger]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgSuccess]}></View>
        </View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>FlexCenter</Text>
        <View style={[{ height: 150 }, rncStyles.bgTransparent, rncStyles.flexRow, rncStyles.mb1, rncStyles.border2, rncStyles.p2, rncStyles.flexCenter]}>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgPrimary]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgDanger]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgSuccess]}></View>
        </View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>FlexWrap</Text>
        <View style={[{ height: 150 }, rncStyles.bgTransparent, rncStyles.flexRow, rncStyles.mb1, rncStyles.border2, rncStyles.p2, rncStyles.flexWrap]}>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgPrimary]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgDanger]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgSuccess]}></View>
        </View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>FlexNoWrap</Text>
        <View style={[{ height: 150 }, rncStyles.bgTransparent, rncStyles.flexRow, rncStyles.mb1, rncStyles.border2, rncStyles.p2, rncStyles.flexNoWrap]}>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgPrimary]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgDanger]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgSuccess]}></View>
        </View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>justifyContentCenter</Text>
        <View style={[{ height: 150 }, rncStyles.bgTransparent, rncStyles.flexRow, rncStyles.mb1, rncStyles.border2, rncStyles.p2, rncStyles.justifyContentCenter]}>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgPrimary]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgDanger]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgSuccess]}></View>
        </View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>justifyContentStart</Text>
        <View style={[{ height: 150 }, rncStyles.bgTransparent, rncStyles.flexRow, rncStyles.mb1, rncStyles.border2, rncStyles.p2, rncStyles.justifyContentStart]}>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgPrimary]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgDanger]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgSuccess]}></View>
        </View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>justifyContentEnd</Text>
        <View style={[{ height: 150 }, rncStyles.bgTransparent, rncStyles.flexRow, rncStyles.mb1, rncStyles.border2, rncStyles.p2, rncStyles.justifyContentEnd]}>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgPrimary]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgDanger]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgSuccess]}></View>
        </View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>alignItemsCenter</Text>
        <View style={[{ height: 150 }, rncStyles.bgTransparent, rncStyles.flexRow, rncStyles.mb1, rncStyles.border2, rncStyles.p2, rncStyles.alignItemsCenter]}>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgPrimary]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgDanger]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgSuccess]}></View>
        </View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>alignItemsStart</Text>
        <View style={[{ height: 150 }, rncStyles.bgTransparent, rncStyles.flexRow, rncStyles.mb1, rncStyles.border2, rncStyles.p2, rncStyles.alignItemsStart]}>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgPrimary]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgDanger]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgSuccess]}></View>
        </View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>alignItemsEnd</Text>
        <View style={[{ height: 150 }, rncStyles.bgTransparent, rncStyles.flexRow, rncStyles.mb1, rncStyles.border2, rncStyles.p2, rncStyles.alignItemsEnd]}>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgPrimary]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgDanger]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgSuccess]}></View>
        </View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>justifyContentBetween</Text>
        <View style={[{ height: 150 }, rncStyles.bgTransparent, rncStyles.flexRow, rncStyles.mb1, rncStyles.border2, rncStyles.p2, rncStyles.justifyContentBetween]}>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgPrimary]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgDanger]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgSuccess]}></View>
        </View>
        <Text style={[rncStyles.textBlack, rncStyles.fs2]}>justifyContentAround</Text>
        <View style={[{ height: 150 }, rncStyles.bgTransparent, rncStyles.flexRow, rncStyles.mb1, rncStyles.border2, rncStyles.p2, rncStyles.justifyContentAround]}>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgPrimary]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgDanger]}></View>
          <View style={[rncStyles.h30, rncStyles.rounded, rncStyles.w10, rncStyles.bgSuccess]}></View>
        </View>
      </View>
    </ScrollView>
  </View>
    </>
	)
};
`

const shadowCode =
  `
import { Text, View } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
    <View style={[rncStyles.h100, rncStyles.p2, rncStyles.bgWhite]}>
      <View style={[rncStyles.bgLight, rncStyles.border1, rncStyles.h10, rncStyles.mb2, rncStyles.shadow1, { shadowColor: 'black' }]}></View>
      <View style={[rncStyles.bgLight, rncStyles.border1, rncStyles.h10, rncStyles.mb2, rncStyles.shadow2, { shadowColor: 'black' }]}></View>
      <View style={[rncStyles.bgLight, rncStyles.border1, rncStyles.h10, rncStyles.mb2, rncStyles.shadow3, { shadowColor: 'black' }]}></View>
      <View style={[rncStyles.bgLight, rncStyles.border1, rncStyles.h10, rncStyles.mb2, rncStyles.shadow4, { shadowColor: 'black' }]}></View>
      <View style={[rncStyles.bgLight, rncStyles.border1, rncStyles.h10, rncStyles.mb2, rncStyles.shadow5, { shadowColor: 'black' }]}></View>
    </View>  
    </>
	)
};
`

const linkCode =
  `
import { Text, View } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
      <View style={[rncStyles.h100, rncStyles.p2, rncStyles.bgWhite]}>
        <Text style={[rncStyles.textBlack, rncStyles.fs1]}>Link</Text>
        <Text style={[rncStyles.link]}>Click here for Details</Text>
      </View>  
    </>
  )
};
`

const inputCode =
  `
import { Text, View } from "react-native";
import rncStyles from 'rncstyles'

export default function App() {
  return( 
    <>
    <View style={[rncStyles.h100, rncStyles.p2, rncStyles.bgLight]}>
     <TextInput placeholder="Input" placeholderTextColor='black' style={[rncStyles.input, rncStyles.border1]}>
    </View>  
    </>
	)
};
`

const paperCode =
  `
import { Text, View } from "react-native";
import rncStyles from 'rncstyles'
  
export default function App() {
  return( 
    <>
    <View style={[rncStyles.h100, rncStyles.p2, rncStyles.bgLight]}>
      <View style={[rncStyles.paper, rncStyles.h50]}>
        <Text style={[rncStyles.textBlack, rncStyles.fs3]}>Paper</Text>
      </View>
    </View>          
    </>
  )
};
`

export { textColorCode, backgroundColorCode, textAllignmentCode, fontSizeCode, paddingCode, marginCode, borderCode, widthCode, buttonCode, chipsCode, positionsCode, borderRadiusCode, flexCode, shadowCode, linkCode, inputCode, paperCode, colorCode };