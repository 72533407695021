import { Box, Divider, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { useRef, useState } from "react";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import BAColors from "../layout/BAColors";
import BABorder from "../layout/BABorder";
import BAMargin from "../layout/BAMargin";
import BAButtonDetails from "../layout/BAButtonDetail";
import BATextColor from "../layout/BATextColors";
import BABackgroundColor from "../layout/BABackgroundColor";
import BATextDetails from "../layout/BATextDetails";
import BAWidth from "../layout/BAWidth";
import BAFontSizeDetails from "../layout/BAFontSizeDetails";
import BAPaddingDetails from "../layout/BAPaddingDetails";
import BAChips from "../layout/BAChips";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import BAModal from "../components/BAModal";
import BAInput from "../components/BAInput";
import { useNavigate } from "react-router-dom";
import BAFlex from "../layout/BAFlex";
import BAShadow from "../layout/BAShadow";
import BAPosition from "../layout/BAPosition";
import BABorderRadius from "../layout/BABorderRadius";
import BALink from "../layout/BALink";
import BAInputfield from "../layout/BAInputfield";
import BAPaper from "../layout/BAPaper";
import BABadge from "../layout/BABadge";
import BACopyButton from "../components/BACopyButton";
import BAButton from "../components/BAButton";
import BAHeight from "../layout/BAHeight";
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { Helmet } from 'react-helmet';
import Footer from "../webLayout/footer";
import HeaderNav from "../webLayout/HeaderNav";


export default function Docs() {
    const [openModal, setOpenModal] = useState(false)
    const colorVariablesContent = useRef();
    const textColorsContent = useRef(null);
    const backgroundColorContent = useRef(null);
    const textContent = useRef(null);
    const fontSizeContent = useRef(null);
    const paddingContent = useRef(null);
    const marginContent = useRef(null);
    const borderContent = useRef(null);
    const widthContent = useRef(null);
    const heightContent = useRef(null);
    const radiusContent = useRef(null);
    const inputContent = useRef(null);
    const buttonDetailsContent = useRef(null);
    const paperDetailsContent = useRef(null);
    const shadowContent = useRef(null);
    const positionsContent = useRef(null);
    const flexContent = useRef(null);
    const linksContent = useRef(null);
    const badgeContent = useRef(null);
    const chipsContent = useRef(null);
    const installation = useRef()

    const [buttonList, setButtonsList] = useState([
        {
            name: 'Color Variables',
            refer: 'colorVariablesContent'
        },
        {
            name: 'Text Color',
            refer: 'textColorsContent'
        },
        {
            name: 'Background Color',
            refer: 'backgroundColorContent'
        },
        {
            name: 'Text',
            refer: 'textContent'
        },
        {
            name: 'Font Size',
            refer: 'fontSizeContent'
        },
        {
            name: 'Padding',
            refer: 'paddingContent'
        },
        {
            name: 'Margin',
            refer: 'marginContent'
        },
        {
            name: 'Border',
            refer: 'borderContent'
        },
        {
            name: 'Width',
            refer: 'widthContent'
        },
        {
            name: 'Height',
            refer: 'heightContent'
        },
        {
            name: 'Radius',
            refer: 'radiusContent'
        },
        {
            name: 'Input',
            refer: 'inputContent'
        },
        {
            name: 'Button',
            refer: 'buttonDetailsContent'
        },
        {
            name: 'Paper',
            refer: 'paperDetailsContent'
        },
        {
            name: 'Shadow',
            refer: 'shadowContent'
        },
        {
            name: 'Positions',
            refer: 'positionsContent'
        },
        {
            name: 'Flex',
            refer: 'flexContent'
        },
        {
            name: 'Link',
            refer: 'linksContent'
        },
        {
            name: 'Badge',
            refer: 'badgeContent'
        },
        {
            name: 'Chips',
            refer: 'chipsContent'
        },
    ])
    const [searchVal, setSearchVal] = useState('')
    const [selectedName, setSelectedName] = useState('')
    const [filteredList, setFilteredList] = useState([])
    const [installer, setInstaller] = useState('npm')


    const handleClick = (refer) => {
        switch (refer) {
            case "colorVariablesContent":
                colorVariablesContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "textColorsContent":
                textColorsContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "backgroundColorContent":
                backgroundColorContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "radiusContent":
                radiusContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "positionsContent":
                positionsContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "chipsContent":
                chipsContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "buttonDetailsContent":
                buttonDetailsContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "widthContent":
                widthContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "borderContent":
                borderContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "marginContent":
                marginContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "paddingContent":
                paddingContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "fontSizeContent":
                fontSizeContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "textContent":
                textContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "badgeContent":
                badgeContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "paperDetailsContent":
                paperDetailsContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "inputContent":
                inputContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "linksContent":
                linksContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "shadowContent":
                shadowContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "flexContent":
                flexContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
            case "heightContent":
                heightContent.current?.scrollIntoView({ behavior: 'smooth' });
                break
        }
    };
    const navigate = useNavigate()

    const instalDocs = () => {
        installation.current?.scrollIntoView({ behavior: 'smooth' });
    };


    return <>
        <Helmet>
            <meta property="og:title" content={"RNC Styles | Docs"} />
            <meta property="og:description" content={"Create stunning mobile user interfaces with ease using RNC Styles – the ultimate solution for React Native developers. Say goodbye to manual styling and hello to a world of pre-built utility styles, all inspired by the best in web design."} />
            <meta property="og:image" content={"https://rncstyles.com/static/media/logo.859b6eba4e978fb4417b.png"} />
            <meta property="og:url" content={"https://rncstyles.com"} />
        </Helmet>
        <BAModal modalTitle="Search" innerContent={<Box>
            <BAInput value={searchVal} onChange={(e) => {
                setSearchVal(e.target.value)
                let list = buttonList.filter(x => x.name.toLowerCase().includes((e.target.value).toLowerCase()))
                setFilteredList([...list])
            }} variant='outlined' label='Search' />

            <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
                <List component="nav" aria-label="secondary mailbox folder">
                    {filteredList.length > 0 ? filteredList.map((x, i) => {
                        return <ListItemButton onClick={() => {
                            setOpenModal(false)
                            handleClick(x.refer)
                        }} key={i}
                        >
                            <ListItemText primary={x.name} />
                        </ListItemButton>
                    }) : buttonList.map((x, i) => {
                        return <ListItemButton onClick={() => {
                            setOpenModal(false)
                            handleClick(x.refer)
                        }} key={i}
                        >
                            <ListItemText primary={x.name} />
                        </ListItemButton>
                    })}
                </List>
            </Box>
        </Box>} width='50vw' modalFooter open={openModal} close={(e) => setOpenModal(e)} />
        <HeaderNav />
        <Box className='bgLight'>
            <Box className='px-3'>
                <Grid container>
                    <Grid item xs={12} sm={12} md={2}>
                        <Box className='p-2 sticky-top h-100vh overflow-auto'>
                            <Box className='text-center p-2'>
                                <Tooltip title='Search Your Component'>
                                    <BAButton onClick={() => setOpenModal(true)} startIcon={<SearchIcon />} label="Search" />
                                </Tooltip>
                            </Box>
                            <List component="nav" aria-label="secondary mailbox folder">
                                <ListItemButton onClick={instalDocs}
                                >
                                    <ListItemText primary={"Installation"} />
                                </ListItemButton>
                            </List>
                            <Divider />
                            <Typography className="text-primary mt-2 p-3 fw-bold" ><ColorLensIcon /> Styling</Typography>
                            <List component="nav" aria-label="secondary mailbox folder">
                                {buttonList.map((x, i) => {
                                    return <ListItemButton selected={x.name == selectedName} onClick={() => {
                                        setSelectedName(x.name)
                                        handleClick(x.refer)
                                    }} key={i}
                                    >
                                        <ListItemText primary={x.name} />
                                    </ListItemButton>
                                })}
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <Box className='p-3'>
                            <Paper ref={installation} className='p-3 bg-white'>
                                <Box className='py-2 mb-3'>
                                    <Typography className="fs-2 fw-bold">Installation</Typography>
                                    <Typography>Install RNC Styles, the easy and  complete React Native Styling.
                                    </Typography>
                                </Box>
                                <Box className='py-2'>
                                    <Typography className="fs-3 fw-bold">Default Installation</Typography>
                                    <Typography>Run this commands to add RNC Styles to your project:
                                    </Typography>
                                    <Box className='mt-4'>
                                        <BAButton variant={installer !== 'npm' ? 'text' : 'contained'} onClick={() => setInstaller('npm')} label='npm' />
                                        <BAButton variant={installer !== 'yarn' ? 'text' : 'contained'} onClick={() => setInstaller('yarn')} label='yarn' />
                                    </Box>
                                    <pre className="p-2 my-3 bg-dark text-white fs-5 rounded shadow">
                                        <BACopyButton code={`${installer === 'npm' ? 'npm i' : 'yarn add'} rncstyles`} />
                                        <code>
                                            {installer === 'npm' ? 'npm i' : 'yarn add'} rncstyles
                                        </code>
                                    </pre>
                                </Box>
                            </Paper>
                            <Box ref={colorVariablesContent}>
                                <BAColors />
                            </Box>
                            <Box ref={textColorsContent}>
                                <BATextColor />
                            </Box>
                            <Box ref={backgroundColorContent}>
                                <BABackgroundColor />
                            </Box>
                            <Box ref={textContent}>
                                <BATextDetails />
                            </Box>
                            <Box ref={fontSizeContent}>
                                <BAFontSizeDetails />
                            </Box>
                            <Box ref={paddingContent}>
                                <BAPaddingDetails />
                            </Box>
                            <Box ref={marginContent}>
                                <BAMargin />
                            </Box>
                            <Box ref={borderContent}>
                                <BABorder />
                            </Box>
                            <Box ref={widthContent}>
                                <BAWidth />
                            </Box>
                            <Box ref={heightContent}>
                                <BAHeight />
                            </Box>
                            <Box ref={radiusContent}>
                                <BABorderRadius />
                            </Box>
                            <Box ref={inputContent}>
                                <BAInputfield />
                            </Box>
                            <Box ref={buttonDetailsContent}>
                                <BAButtonDetails />
                            </Box>
                            <Box ref={paperDetailsContent}>
                                <BAPaper />
                            </Box>
                            <Box ref={shadowContent}>
                                <BAShadow />
                            </Box>
                            <Box ref={positionsContent}>
                                <BAPosition />
                            </Box>

                            <Box ref={flexContent}>
                                <BAFlex />
                            </Box>

                            <Box ref={linksContent}>
                                <BALink />
                            </Box>

                            <Box ref={badgeContent}>
                                <BABadge />
                            </Box>
                            <Box ref={chipsContent}>
                                <BAChips />
                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Footer />
    </>
}