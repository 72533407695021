import BAIconbutton from "./BAIconbutton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BAShowError from "./BAShowError";
import { useState } from "react";

export default function BACopyButton({ code }) {
    const [showMessage, setShowMessage] = useState(false)
    const copyCode = () => {
        navigator.clipboard.writeText(code);
        setShowMessage(true)
    }
    return <>
        <BAShowError message="Copied to Clipboard...!" open={showMessage} close={(e) => setShowMessage(e)} />
        <BAIconbutton onClick={copyCode} icon={<ContentCopyIcon />} />
    </>
}