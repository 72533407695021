import BAContent from "../components/BAContent";
import { useState } from "react";
import BAGrid from "../components/BAGrid";
import { Box, Grid, Typography } from "@mui/material";
import BAMobileView from "../components/BAMobileView";
import styling from "../rnTheme/rncstyles";
import { EmailRounded } from "@mui/icons-material";

function BABadgeComp({ badgestyle }) {
  return (
    <Box
      sx={{
        height: "320px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        className="bg-light shadow rounded"
        sx={{ height: "50%", width: "90%" }}
      >
        <Box className="d-flex w-100 h-100 justify-content-center shadow">
          <Box sx={{ height: "57%", width: "35%" }}>
            <EmailRounded
              color="action"
              sx={{ height: "100%", width: "100%" }}
            />
          </Box>
          <Box
            className=" text-center"
            sx={{ height: "40%", width: "9%", position: "relative" }}
          >
            <Box
              sx={{
                ...badgestyle,
                backgroundColor: styling._danger,
                left: -17,
                padding: 2.2,
              }}
            ></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default function BABadge() {
  const [badge, setBadge] = useState([
    {
      name: "badge",
      value: "",
    },
  ]);

  return (
    <BAContent
      heading="Badge"
      gridSection={
        <BAGrid
          datasource={badge}
          gridCols={[
            {
              key: "name",
              label: "Name",
            },
            {
              key: "value",
              label: "Value",
            },
          ]}
        />
      }
      codeSection={
        <Box className="px-2">
          <pre className="bg-dark h-100 text-white p-2 shadow rounded">
            import styling from './styling'
          </pre>
        </Box>
      }
      mobileSection={
        <BAMobileView>
          <Box className="py-2">
            <Typography className="p-2" variant="h4">
              Badge
            </Typography>
            <BABadgeComp badgestyle={styling.badge} />
          </Box>
        </BAMobileView>
      }
    />
  );
}
