import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { primary } from '@mui/material/colors';


export default function BAButton({ label, onClick, startIcon, variant, endIcon }) {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#3949ab',
            },
        },
    });
    return <>
        <ThemeProvider theme={theme}>
            <Button sx={{ borderRadius: 20, boxShadow: '0 5px 8px rgba(0,0,0,0.4)', paddingX: 4, marginX: 1 }} endIcon={endIcon} startIcon={startIcon} variant={variant ?? "contained"} onClick={onClick}>{label}</Button>
        </ThemeProvider>
    </>
}