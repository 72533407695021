import { Box, Typography } from "@mui/material";
import BAMobileView from "../components/BAMobileView";
import BAContent from "../components/BAContent";
import styling from "../rnTheme/rncstyles";
import BAShowcolor from "../components/BAShowcolor";
import BAGrid from "../components/BAGrid";
import { useState } from "react";
import { colorCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";

export default function BAColors() {
    const [colors, setColors] = useState([
        {
            name: '_dark',
            value: styling._dark
        },
        {
            name: '_secondary',
            value: styling._secondary
        },
        {
            name: '_success',
            value: styling._success
        },
        {
            name: '_danger',
            value: styling._danger
        },
        {
            name: '_info',
            value: styling._info
        },
        {
            name: '_black',
            value: styling._black
        },
        {
            name: '_white',
            value: styling._white
        },
    ])
    return <>
        <BAContent gridSection={<BAGrid datasource={colors} gridCols={[
            {
                key: 'name',
                label: "Name"
            },
            {
                key: 'value',
                label: "Value"
            },
        ]}
        />
        }
            codeSection={
                <Box className='px-2'>
                    <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                        <BACopyButton code={colorCode} />
                        <code>
                            {colorCode}
                        </code>
                    </pre>
                </Box>
            }
            mobileSection={<BAMobileView>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._dark} />
                    <Typography className="ms-2">_dark</Typography>
                </Box>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._secondary} />
                    <Typography className="ms-2">_secondary</Typography>
                </Box>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._success} />
                    <Typography className="ms-2">_success</Typography>
                </Box>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._danger} />
                    <Typography className="ms-2">_danger</Typography>
                </Box>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._info} />
                    <Typography className="ms-2">_info</Typography>
                </Box>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._black} />
                    <Typography className="ms-2">_black</Typography>
                </Box>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._white} />
                    <Typography className="ms-2">_white</Typography>
                </Box>

            </BAMobileView>} heading='Color Variables'>
        </BAContent>
    </>
}