import { Box } from "@mui/system";
import nodata from "../assets/images/nodata.jpg";
import BALoader from "./BALoader";

function BAGrid(props) {
    const { datasource, gridCols, loading } = props;
    return (
        <>
            {loading ? (
                <BALoader />
            ) : datasource && datasource.length < 1 ? (
                <div className="d-flex justify-content-center align-items-center">
                    <img alt='No Data Found' src={nodata} width="30%" />
                </div>
            ) : (
                <Box>
                    <table className="table table-striped rounded overflow-hidden text-start">
                        <thead className="bgPrimary text-white">
                            <tr>
                                {Array.isArray(gridCols)
                                    ? gridCols.map((e, i) => <th key={i}>{e.label}</th>)
                                    : null}
                            </tr>
                        </thead>
                        <tbody className="gridBody">
                            {Array.isArray(datasource)
                                ? datasource.map((e, i) => (
                                    <tr key={i}>
                                        {gridCols
                                            ? gridCols.map((a, b) => (
                                                <td
                                                    className={
                                                        a.editRow || a.deleteRow
                                                            ? "btnCell"
                                                            : a.className
                                                                ? a.className
                                                                : ""
                                                    }
                                                    key={b}
                                                >
                                                    {a.displayField
                                                        ? a.displayField(e, i)
                                                        : e[a.key]}
                                                </td>
                                            ))
                                            : null}
                                    </tr>
                                ))
                                : null}
                        </tbody>
                    </table>
                </Box>
            )}
        </>
    );
}
export default BAGrid;