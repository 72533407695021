import IconButton from "@mui/material/IconButton";

function BAIconbutton(props) {
    const { icon, onClick, color, variant } = props;
    return (
        <>
            <IconButton
                variant={variant ?? "Contained"}
                color={color ?? "primary"}
                aria-label="upload picture"
                component="span"
                onClick={onClick}
            >
                {icon}
            </IconButton>
        </>
    );
}
export default BAIconbutton;