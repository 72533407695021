import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import BAMobileView from "../components/BAMobileView";
import BAContent from "../components/BAContent";
import BAGrid from "../components/BAGrid";
import styling from "../rnTheme/rncstyles";
import { positionsCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";


function MainBox({
  OutterText,
  position,
  InnerText,
  backgroundColor,
  Text,
  sx,
  className
}) {
  return (
    <>
      <Box className="my-1 align-items-center">
        <Typography className="p-2" variant="h5">
          {OutterText}
        </Typography>
        <Box className="row d-flex justify-content-center align-items-center m-0 p-0 py-2 "  >
          <Box
            className="my-3"
            height="20vh"
            sx={{
              border: "1px solid black",
              backgroundColor: "skyblue",
              color: styling._light,
              fontSize: styling.fs4,
              fontWeight: "bold",
              letterSpacing: "2px",
            }}
            position={styling.positionRelative.position}

          >
            {InnerText}
            <Box
              className="col-11 d-flex justify-content-center align-items-center flex-column "
              height="15vh"
              sx={{ sx }}
            >
              <Box
                backgroundColor={backgroundColor} className={className}
              >
                <Typography
                  sx={{
                    color: styling._light,
                    fontSize: styling.fs6,
                    fontWeight: "bold",
                    letterSpacing: "1px",
                  }}
                >
                  {Text}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default function BAPosition() {
  const [position, setPosition] = useState([
    {
      name: "positionRelative",
      value: JSON.stringify(styling.positionRelative),
    },
    {
      name: "positionAbsolute",
      value: JSON.stringify(styling.positionAbsolute),
    }
  ]);
  return (
    <>
      <BAContent
        heading="positions"
        gridSection={
          <BAGrid
            datasource={position}
            gridCols={[
              {
                key: "name",
                label: "Name",
              },
              {
                key: "value",
                label: "value",
              }
            ]}
          />
        }
        codeSection={
          <Box className="px-2">
            <pre className="bg-dark h-100 text-white p-2 shadow rounded">
              <BACopyButton code={positionsCode} />
              <code>
                {positionsCode}
              </code>
            </pre>
          </Box>
        }
        mobileSection={
          <BAMobileView>
            <MainBox
              position={styling.positionRelative.position}
              OutterText="position Relative"
              InnerText="positionrelative"
            />
            <MainBox
              OutterText="position Absolute"
              InnerText="position Relative"
              Text="positionAbsolute"
              position={styling.positionRelative.position}
              sx={styling.positionAbsolute}
              backgroundColor={styling.bgSecondary}
              className="py-2 px-3 rounded shadow"
            />
          </BAMobileView>

        }
      />
    </>
  );
}
