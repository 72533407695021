import { useState } from "react";
import { Box, Typography } from "@mui/material";
import BAMobileView from "../components/BAMobileView";
import BAContent from "../components/BAContent";
import BAGrid from "../components/BAGrid";
import { shadowCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";
// import styling from "../rnTheme/rncstyles";

const Style = {
  background: {
    backgroundColor: "#ffffff",
  },
  shadow1: {
    boxShadow: `0px 1px 3px #bdb8b8`,
  },
  shadow2: {
    boxShadow: `1px 2px 6px #bdb8b8`,
  },
  shadow3: {
    boxShadow: `1px 2px 9px #bdb8b8`,
  },
  shadow4: {
    boxShadow: `2px 4px 12px #bdb8b8`,
  },
  shadow5: {
    boxShadow: "2px 4px 18px #bdb8b8",
  },
  shadow6: {
    boxShadow: "4px 8px 36px #bdb8b8",
  },
};

function BoxShadowWithName({ title, sx }) {
  return (
    <Box className="my-1 align-items-center">
      <Typography className="p-2" variant="h5">
        {title}
      </Typography>
      <Box className=" d-flex justify-content-center align-items-center m-0 py-4">
        <Box
          className="rounded"
          height="45px"
          width="70px"
          backgroundColor={Style.background}
          sx={sx}
        ></Box>
      </Box>
    </Box>
  );
}

export default function BAShadow() {
  const [shadow, setShadow] = useState([
    {
      name: "shadow1",
      value: "-",
    },
    {
      name: "shadow2",
      value: "-",
    },
    {
      name: "shadow3",
      value: "-",
    },
    {
      name: "shadow4",
      value: "-",
    },
    {
      name: "shadow5",
      value: "-",
    },
    {
      name: "shadow6",
      value: "-",
    },
  ]);

  return (
    <>
      <BAContent
        heading="Shadow"
        gridSection={
          <BAGrid
            datasource={shadow}
            gridCols={[
              {
                key: "name",
                label: "Name",
              },
              {
                key: "value",
                label: "value",
              },
            ]}
          />
        }
        codeSection={
          <Box className="px-2">
            <pre className="bg-dark h-100 text-white p-2 shadow rounded">
              <BACopyButton code={shadowCode} />
              <code>
                {shadowCode}
              </code>
            </pre>
          </Box>
        }
        mobileSection={
          <BAMobileView>
            <BoxShadowWithName title="Shadow1" sx={Style.shadow1} />
            <BoxShadowWithName title="Shadow2" sx={Style.shadow2} />
            <BoxShadowWithName title="Shadow2" sx={Style.shadow3} />
            <BoxShadowWithName title="Shadow3" sx={Style.shadow4} />
            <BoxShadowWithName title="Shadow4" sx={Style.shadow5} />
            <BoxShadowWithName title="Shadow5" sx={Style.shadow6} />
          </BAMobileView>
        }
      />
    </>
  );
}
