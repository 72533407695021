import { useState } from "react";
import BAContent from "../components/BAContent";
import styling from "../rnTheme/rncstyles";
import BAMobileView from "../components/BAMobileView";
import BAGrid from "../components/BAGrid";
import { buttonCode } from "../config/showCode";
import { Box } from "@mui/material";
import BACopyButton from "../components/BACopyButton";

export default function BAButtonDetails() {
    const [listData, setListData] = useState([
        {
            name: 'btn',
            value: JSON.stringify(styling.btn)
        }
    ])
    return <>
        <BAContent gridSection={<BAGrid datasource={listData} gridCols={[
            {
                key: 'name',
                label: "Name"
            },
            {
                key: 'value',
                label: "Value"
            },
        ]} />} codeSection={
            <Box className='px-2'>
                <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                    <code>
                        <BACopyButton code={buttonCode} />
                        {buttonCode}
                    </code>
                </pre>
            </Box>

        } mobileSection={<BAMobileView>
            <button className="btn" style={styling.btn}>Click</button>
            <button className="btn" style={styling.btnPrimary}>Click</button>
            <button className="btn" style={styling.btnSecondary}>Click</button>
        </BAMobileView>} heading="Button" />
    </>
}