import { Box } from "@mui/material";

export default function BAMobileView({ children }) {
    return <>
        <Box className='mobileView'>
            <Box className='h-100 overflow-auto'>
                {children}
            </Box>
        </Box>
    </>
}