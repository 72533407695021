import { Box, Typography } from "@mui/material";
import BAContent from "../components/BAContent";
import BAMobileView from "../components/BAMobileView";
import BATxt from "../components/BATxt";
import styling from "../rnTheme/rncstyles";
import BAGrid from "../components/BAGrid";
import { useState } from "react";
import BAPadding from "../components/BAPadding";
import { paddingCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";

export default function BAPaddingDetails() {

    const [paddings, setPaddings] = useState([
        {
            name: "p1",
            value: styling.p1.padding,
        },
        {
            name: "p2",
            value: styling.p2.padding,
        },
        {
            name: "p3",
            value: styling.p3.padding,
        },
        {
            name: "p4",
            value: styling.p4.padding,
        },
        {
            name: "p5",
            value: styling.p5.padding,
        },
        {
            name: "py1",
            value: styling.py1.padding,
        },
        {
            name: "py2",
            value: styling.py2.padding,
        },
        {
            name: "py3",
            value: styling.py3.padding,
        },
        {
            name: "py4",
            value: styling.py4.padding,
        },
        {
            name: "py5",
            value: styling.py5.padding,
        },
        {
            name: "px1",
            value: styling.px1.padding,
        },
        {
            name: "px2",
            value: styling.px2.padding,
        },
        {
            name: "px3",
            value: styling.px3.padding,
        },
        {
            name: "px4",
            value: styling.px4.padding,
        },
        {
            name: "px5",
            value: styling.px5.padding,
        },
        {
            name: "ps1",
            value: styling.ps1.paddingLeft,
        },
        {
            name: "ps2",
            value: styling.ps2.paddingLeft,
        },
        {
            name: "ps3",
            value: styling.ps3.paddingLeft,
        },
        {
            name: "ps4",
            value: styling.ps4.paddingLeft,
        },
        {
            name: "ps5",
            value: styling.ps5.paddingLeft,
        },
        {
            name: "pe1",
            value: styling.pe1.paddingEnd,
        },
        {
            name: "pe2",
            value: styling.pe2.paddingEnd,
        },
        {
            name: "pe3",
            value: styling.pe3.paddingEnd,
        },
        {
            name: "pe4",
            value: styling.pe4.paddingEnd,
        },
        {
            name: "pe5",
            value: styling.pe5.paddingEnd,
        },
        {
            name: "pt1",
            value: styling.pt1.paddingTop,
        },
        {
            name: "pt2",
            value: styling.pt2.paddingTop,
        },
        {
            name: "pt3",
            value: styling.pt3.paddingTop,
        },
        {
            name: "pt4",
            value: styling.pt4.paddingTop,
        },
        {
            name: "pt5",
            value: styling.pt5.paddingTop,
        },
        {
            name: "pb1",
            value: styling.pb1.paddingBottom,
        },
        {
            name: "pb2",
            value: styling.pb2.paddingBottom,
        },
        {
            name: "pb3",
            value: styling.pb3.paddingBottom,
        },
        {
            name: "pb4",
            value: styling.pb4.paddingBottom,
        },
        {
            name: "pb5",
            value: styling.pb5.paddingBottom,
        },
    ]);

    return <>
        <BAContent codeSection={
            <Box className='px-2' sx={{ height: 700 }}>
                <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                    <BACopyButton code={paddingCode} />
                    <code>
                        {paddingCode}
                    </code>
                </pre>
            </Box>
        } gridSection={<BAGrid datasource={paddings} gridCols={[
            {
                key: 'name',
                label: "Name"
            },
            {
                key: 'value',
                label: "Value"
            },
        ]} />} mobileSection={<BAMobileView>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">p1</Typography>
                <BAPadding padding={styling.p1} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">p2</Typography>
                <BAPadding padding={styling.p2} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">p3</Typography>
                <BAPadding padding={styling.p3} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">p4</Typography>
                <BAPadding padding={styling.p4} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">p5</Typography>
                <BAPadding padding={styling.p5} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">px1</Typography>
                <BAPadding padding={styling.px1} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">px2</Typography>
                <BAPadding padding={styling.px2} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">px3</Typography>
                <BAPadding padding={styling.px3} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">px4</Typography>
                <BAPadding padding={styling.px4} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">px5</Typography>
                <BAPadding padding={styling.px5} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">py1</Typography>
                <BAPadding padding={styling.py1} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">py2</Typography>
                <BAPadding padding={styling.py2} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">py3</Typography>
                <BAPadding padding={styling.py3} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">py4</Typography>
                <BAPadding padding={styling.py4} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">py5</Typography>
                <BAPadding padding={styling.py5} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">ps1</Typography>
                <BAPadding padding={styling.ps1} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">ps2</Typography>
                <BAPadding padding={styling.ps2} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">ps3</Typography>
                <BAPadding padding={styling.ps3} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">ps4</Typography>
                <BAPadding padding={styling.ps4} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">ps5</Typography>
                <BAPadding padding={styling.ps5} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pe1</Typography>
                <BAPadding padding={styling.pe1} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pe2</Typography>
                <BAPadding padding={styling.pe2} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pe3</Typography>
                <BAPadding padding={styling.pe3} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pe4</Typography>
                <BAPadding padding={styling.pe4} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pe5</Typography>
                <BAPadding padding={styling.pe5} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pt1</Typography>
                <BAPadding padding={styling.pt1} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pt2</Typography>
                <BAPadding padding={styling.pt2} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pt3</Typography>
                <BAPadding padding={styling.pt3} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pt4</Typography>
                <BAPadding padding={styling.pt4} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pt5</Typography>
                <BAPadding padding={styling.pt5} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pb1</Typography>
                <BAPadding padding={styling.pb1} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pb2</Typography>
                <BAPadding padding={styling.pb2} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pb3</Typography>
                <BAPadding padding={styling.pb3} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pb4</Typography>
                <BAPadding padding={styling.pb4} />
            </Box>
            <Box className='py-2'>
                <Typography className="p-2" variant="h4">pb5</Typography>
                <BAPadding padding={styling.pb5} />
            </Box>
        </BAMobileView>} heading="Padding">

        </BAContent>
    </>
}