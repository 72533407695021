import { Box, Typography } from "@mui/material";
import BAContent from "../components/BAContent";
import BAMobileView from "../components/BAMobileView";
import BATxt from "../components/BATxt";
import styling from "../rnTheme/rncstyles";
import BAGrid from "../components/BAGrid";
import { useState } from "react";
import BAShowcolor from "../components/BAShowcolor";
import { backgroundColorCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";

export default function BABackgroundColor() {
    const [textColorList, setTextColorList] = useState([
        {
            name: 'bgPrimary',
            value: styling._dark
        },
        {
            name: 'bgSecondary',
            value: styling._secondary
        },
        {
            name: 'bgSuccess',
            value: styling._success
        },
        {
            name: 'bgDanger',
            value: styling._danger
        },
        {
            name: 'bgInfo',
            value: styling._info
        },
        {
            name: 'bgWhite',
            value: styling._white
        },
    ])
    return <>
        <BAContent codeSection={
            <Box className='px-2'>
                <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                    <BACopyButton code={backgroundColorCode} />
                    <code>
                        {backgroundColorCode}
                    </code>
                </pre>
            </Box>
        }
            gridSection={<BAGrid datasource={textColorList} gridCols={[
                {
                    key: 'name',
                    label: "Name"
                },
                {
                    key: 'value',
                    label: "Value"
                },
            ]} />} mobileSection={<BAMobileView>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._dark} />
                    <Typography className="ms-2">bgPrimary</Typography>
                </Box>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._secondary} />
                    <Typography className="ms-2">bgSecondary</Typography>
                </Box>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._success} />
                    <Typography className="ms-2">bgSuccess</Typography>
                </Box>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._danger} />
                    <Typography className="ms-2">bgDanger</Typography>
                </Box>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._info} />
                    <Typography className="ms-2">bgInfo</Typography>
                </Box>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._black} />
                    <Typography className="ms-2">bgBlack</Typography>
                </Box>
                <Box className='text-center my-2 d-flex align-items-center'>
                    <BAShowcolor color={styling._white} />
                    <Typography className="ms-2">bgWhite</Typography>
                </Box>

            </BAMobileView>} heading="Background Color">

        </BAContent>
    </>
}