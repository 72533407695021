import React, { useState } from 'react';
import axios from 'axios';

const MsgBoat = () => {
    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');

    const sendMessage = async () => {
        try {
            const response = await axios.post(
                'https://api.openai.com/v1/engines/davinci-codex/completions',
                {
                    prompt: input,
                    max_tokens: 50, // Adjust based on the desired response length.
                },
                {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_API_Key}`,
                    },
                }
            );

            setOutput(response.data.choices[0].text);
        } catch (error) {
            console.error('Error sending message to ChatGPT: ', error);
        }
    };

    return (
        <div>
            <div>
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                />
                <button onClick={sendMessage}>Send</button>
            </div>
            <div>
                <p>Input: {input}</p>
                <p>Output: {output}</p>
            </div>
        </div>
    );
};

export default MsgBoat;
