import { useState } from "react";
import { Box, Typography } from "@mui/material";
import BAMobileView from "../components/BAMobileView";
import BAContent from "../components/BAContent";
import BAGrid from "../components/BAGrid";
import styling from "../rnTheme/rncstyles";

function BoxHeightWithName({ title, height }) {
  return (
    <Box className="my-1 align-items-center">
      <Typography className="p-2" variant="h5">
        {title}
      </Typography>
      <Box
      className="row d-flex row align-items-end m-0 p-0"
        height="15vh"
        sx={{ border: "1px solid black" }}
      >
        <Box
          height={height}
          className="col-12"
          sx={{ backgroundColor: "skyblue" }}
        ></Box>
      </Box>
    </Box>
  );
}

export default function BAHeight() {
  const [height, setHeight] = useState([
    {
      name: "h10",
      value: JSON.stringify(styling.h10),
    },
    {
      name: "h20",
      value: JSON.stringify(styling.h20),
    },
    {
      name: "h25",
      value: JSON.stringify(styling.h25),
    },
    {
      name: "h30",
      value: JSON.stringify(styling.h30),
    },
    {
      name: "h40",
      value: JSON.stringify(styling.h40),
    },
    {
      name: "h50",
      value: JSON.stringify(styling.h50),
    },
    {
      name: "h60",
      value: JSON.stringify(styling.h60),
    },
    {
      name: "h70",
      value: JSON.stringify(styling.h60),
    },
    {
      name: "h75",
      value: JSON.stringify(styling.h75),
    },
    {
      name: "h80",
      value: JSON.stringify(styling.h80),
    },
    {
      name: "h90",
      value: JSON.stringify(styling.h90),
    },
    {
      name: "h100",
      value: JSON.stringify(styling.h100),
    },
  ]);

  return (
    <>
      <BAContent
        heading="Height"
        gridSection={
          <BAGrid
            datasource={height}
            gridCols={[
              {
                key: "name",
                label: "Name",
              },
              {
                key: "value",
                label: "value",
              },
            ]}
          />
        }
        codeSection={
          <Box className="px-2">
            <pre className="bg-dark h-100 text-white p-2 shadow rounded">
              import styling from './styling'
            </pre>
          </Box>
        }
        mobileSection={
          <BAMobileView>
            <BoxHeightWithName title="h10" height={styling.h10} />
            <BoxHeightWithName title="h20" height={styling.h20} />
            <BoxHeightWithName title="h25" height={styling.h25} />
            <BoxHeightWithName title="h30" height={styling.h30} />
            <BoxHeightWithName title="h40" height={styling.h40} />
            <BoxHeightWithName title="h50" height={styling.h50} />
            <BoxHeightWithName title="h60" height={styling.h60} />
            <BoxHeightWithName title="h70" height={styling.h70} />
            <BoxHeightWithName title="h75" height={styling.h75} />
            <BoxHeightWithName title="h80" height={styling.h80} />
            <BoxHeightWithName title="h90" height={styling.h90} />
            <BoxHeightWithName title="h100" height={styling.h100} />
          </BAMobileView>
        }
      />
    </>
  );
}
