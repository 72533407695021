import { useState } from "react";
import { Box } from "@mui/material";
import BAMobileView from "../components/BAMobileView";
import BAContent from "../components/BAContent";
import BAGrid from "../components/BAGrid";
import { inputCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";

const styled = {
  inputStyle: {
    width: "100%",
    boxShadow: "none",
    height: "5vh",
    outline: "none",
    borderRadius: "5px",
    border: '1px solid #bdb8b8'

  },
  inputFocusStyle: {
    width: "100%",
    boxShadow: '0px 1px 3px #80bdff',
    height: "5vh",
    outline: "none",
    borderRadius: "5px",
    border: '1px solid #80bdff'

  }
}

function MainInputField({ title }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseFocus = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div className="row m-0 p-0 px-1 pt-2">
        <input
          placeholder={title}
          style={isHovered ? styled.inputFocusStyle : styled.inputStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onFocus={handleMouseFocus}
        />
      </div>
    </>
  );
}

export default function BAInputfield() {
  const [input, setInput] = useState([
    {
      name: "Input",
      value: "-",
    },
  ]);
  return (
    <>
      <BAContent
        heading="Input"
        gridSection={
          <BAGrid
            datasource={input}
            gridCols={[
              {
                key: "name",
                label: "Name",
              },
              {
                key: "value",
                label: "value",
              },
            ]}
          />
        }
        codeSection={
          <Box className="px-2">
            <pre className="bg-dark h-100 text-white p-2 shadow rounded">
              <BACopyButton code={inputCode} />
              <code>
                {inputCode}
              </code>
            </pre>
          </Box>
        }
        mobileSection={
          <BAMobileView>
            <MainInputField title="Input" />
          </BAMobileView>
        }
      />
    </>
  );
}
