import { Box, Container, Grid, Typography } from "@mui/material";
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import logo from '../assets/images/logo.png'
import discordicon from '../assets/images/discord-icon.svg'
import { Link } from "react-router-dom";

export default function Footer() {
    return <>
        <Box>
            <Box className='bg-white py-5'>
                <Container>
                    <Grid container>
                        <Grid item md={4} xs={12} sm={12}>
                            <Box className='p-3 footerCols'>
                                <img width={80} alt="RNC Styles" src={logo} />
                                <Typography className="fs-4">RNC Style</Typography>
                                <Typography className="text-muted">Code Licence ISC</Typography>
                                <Typography className="text-muted">Currently v2.0.1</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12} sm={12}>
                            <Box className='p-3 footerCols'>
                                <Box>
                                    <Typography className="fw-bold fs-5">Usefull Links: </Typography>

                                    <Link className="fs-5 d-block" to='/aboutus'>About Us</Link>
                                    <Link className="fs-5 d-block" to='/components'>Components</Link>
                                    <Link className="fs-5 d-block" to='/docs'>Documentation</Link>
                                    <Link className="fs-5 d-block" to='/uitemplates'>Templates</Link>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12} sm={12}>
                            <Box className='p-3 footerCols'>
                                <Box>
                                    <Typography className="fw-bold fs-5">Follow Us: </Typography>
                                    <a target="_blank" className="d-inline-block p-1" href="https://github.com/BasitAyaz/react_native_styles">
                                        <GitHubIcon sx={{ fontSize: 30 }} />
                                    </a>
                                    <a target="_blank" className="d-inline-block p-1" href="https://discord.gg/SpzxxTy8">
                                        <img width={30} src={discordicon} alt="Discord" />
                                    </a>
                                    <a target="_blank" className="d-inline-block p-1" href="https://www.facebook.com/rncstyles">
                                        <FacebookIcon sx={{ fontSize: 30 }} />
                                    </a>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className='container p-2 text-center'>
                <Typography>© 2023 HBRM | All rights reserved | Designed with ❤️ by Basit Ahmed</Typography>
            </Box>
        </Box>
    </>
}