import { Box, Paper, Typography } from "@mui/material"
import BAContent from "../components/BAContent"
import BAMobileView from "../components/BAMobileView"
import { useState } from "react";
import BAGrid from "../components/BAGrid";
import { paperCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";



export default function BAPaper() {
    const [paper, setPaper] = useState([
        {
            name: 'Paper',
            value: `padding: 10,
            backgroundColor: 'white',
            borderRadius: 10,
            margin: 2,
            shadowColor: "rgba(0,0,0,.5)",
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowOpacity: 0.3,
            shadowRadius: 4.65,

            elevation: 8,`
        },

    ]);
    return (
        <>
            <BAContent heading={'Paper'}

                gridSection={
                    <BAGrid
                        datasource={paper}
                        gridCols={[
                            {
                                key: "name",
                                label: "Name",
                            },
                            {
                                key: "value",
                                label: "Value",
                            },
                        ]}
                    />}
                codeSection={<Box className="px-2">
                    <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                        <BACopyButton code={paperCode} />
                        <code>
                            {paperCode}
                        </code>
                    </pre>
                </Box>}
                mobileSection={
                    <BAMobileView >
                        <Box className='p-2' sx={{ height: '65vh', backgroundColor: '#F2F2F2' }} >
                            <Typography className="p-2 text-decoration-underline" variant="h5">Paper</Typography>
                            <Box className='p-2' >
                                <Paper className='p-5 text-center w-100 mt-3' >
                                    Paper
                                </Paper>
                            </Box>
                        </Box>
                    </BAMobileView >
                } />

        </>
    )
}