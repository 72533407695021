import TextField from "@mui/material/TextField";

function BAInput(props) {
    const {
        label,
        type,
        variant,
        onChange,
        required,
        disabled,
        value,
        rows,
        multiline,
    } = props;
    return (
        <>
            <TextField
                id="outlined-basic"
                type={type}
                label={label}
                onChange={onChange}
                required={required}
                value={value ? value : ""}
                disabled={disabled}
                fullWidth={true} ///me
                variant={variant ? variant : "standard"}
                multiline={multiline}
                rows={rows}
            />
        </>
    );
}
export default BAInput;