import { Box } from "@mui/material";
import BAContent from "../components/BAContent";
import BAMobileView from "../components/BAMobileView";
import BATxt from "../components/BATxt";
import styling from "../rnTheme/rncstyles";
import BAGrid from "../components/BAGrid";
import { useState } from "react";
import { textColorCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";

export default function BATextColor() {
    const [textColorList, setTextColorList] = useState([
        {
            name: 'textPrimary',
            value: styling._dark
        },
        {
            name: 'textSecondary',
            value: styling._secondary
        },
        {
            name: 'textSuccess',
            value: styling._success
        },
        {
            name: 'textDanger',
            value: styling._danger
        },
        {
            name: 'textInfo',
            value: styling._info
        },
        {
            name: 'textWhite',
            value: styling._white
        },
    ])
    return <>
        <BAContent codeSection={
            <Box className='px-2'>
                <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                    <BACopyButton code={textColorCode} />
                    <code>
                        {textColorCode}
                    </code>
                </pre>
            </Box>
        } gridSection={<BAGrid datasource={textColorList} gridCols={[
            {
                key: 'name',
                label: "Name"
            },
            {
                key: 'value',
                label: "Value"
            },
        ]} />} mobileSection={<BAMobileView>
            <BATxt color={styling._dark} text='name : textPrimary' />
            <BATxt color={styling._secondary} text='name : textSecondary' />
            <BATxt color={styling._success} text='name : textSuccess' />
            <BATxt color={styling._danger} text='name : textDanger' />
            <BATxt color={styling._info} text='name : textInfo' />
            <Box className='bg-dark'>
                <BATxt color={styling._white} text='name : textWhite' />
            </Box>
        </BAMobileView>} heading="Text Color">

        </BAContent>
    </>
}