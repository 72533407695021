import { useState } from "react";
import BAContent from "../components/BAContent";
import BAMobileView from "../components/BAMobileView";
import { Typography, Box } from "@mui/material";
import styling from "../rnTheme/rncstyles";
import BAGrid from "../components/BAGrid";
import { chipsCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";

export default function BAChips() {
    const [listData, setListData] = useState([
        {
            name: 'chips',
            value: 'chips'
        }
    ])
    return <>
        <BAContent heading="Chips" gridSection={<BAGrid datasource={listData} gridCols={[
            {
                key: 'name',
                label: "Name"
            },
            {
                key: 'value',
                label: "Value"
            },
        ]} />} codeSection={
            <Box className='px-2'>
                <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                    <BACopyButton code={chipsCode} />
                    <code>
                        {chipsCode}
                    </code>
                </pre>
            </Box>
        } mobileSection={<BAMobileView>
            <Typography sx={styling.chips}>ABC</Typography>
        </BAMobileView>} />
    </>
}