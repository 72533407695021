import { Box, Typography } from '@mui/material'

export default function BABoard() {
    return <>
        <Box className='bg-black'>
            <Box className='container text-white p-2'>
                <Typography className='text-center'>We Stand With Palestine. 🇵🇸</Typography>
            </Box>
        </Box>
    </>
}