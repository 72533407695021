import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRouter from './config/approuter';
import { Helmet } from 'react-helmet';



function App() {
  return (<>
    <Helmet>
      <meta property="og:title" content={"RNC Styles"} />
      <meta property="og:description" content={"Create stunning mobile user interfaces with ease using RNC Styles – the ultimate solution for React Native developers. Say goodbye to manual styling and hello to a world of pre-built utility styles, all inspired by the best in web design."} />
      <meta property="og:image" content={"https://rncstyles.com/static/media/logo.859b6eba4e978fb4417b.png"} />
      <meta property="og:url" content={"https://rncstyles.com"} />
    </Helmet>
    <AppRouter />
  </>
  );
}

export default App;
