import { Box, Grid, Typography } from "@mui/material";
import BAContent from "../components/BAContent";
import BAMobileView from "../components/BAMobileView";

import { useState } from "react";
import styling from "../rnTheme/rncstyles";
import BAGrid from "../components/BAGrid";
import { marginCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";

export function BAMarginCom({ margin }) {
  return (
    <>
      <Box
        className="bg-warning border border-white text-white"
        sx={{ width: "100%", height: "100%" }}
      >
        <Box
          sx={{ margin, height: 100, border: "2px solid black" }}
          className="bg-light"
        ></Box>
      </Box>
    </>
  );
}

export default function BAMargin() {
  const [margins, setMargins] = useState([
    {
      name: "m1",
      value: styling.m1.margin,
    },
    {
      name: "m2",
      value: styling.m2.margin,
    },
    {
      name: "m3",
      value: styling.m3.margin,
    },
    {
      name: "m4",
      value: styling.m4.margin,
    },
    {
      name: "m5",
      value: styling.m5.margin,
    },
    {
      name: "my1",
      value: styling.my1.marginVertical,
    },
    {
      name: "my2",
      value: styling.my2.marginVertical,
    },
    {
      name: "my3",
      value: styling.my3.marginVertical,
    },
    {
      name: "my4",
      value: styling.my4.marginVertical,
    },
    {
      name: "my5",
      value: styling.my5.marginVertical,
    },
    {
      name: "mx1",
      value: styling.mx1.marginHorizontal,
    },
    {
      name: "mx2",
      value: styling.mx2.marginHorizontal,
    },
    {
      name: "mx3",
      value: styling.mx3.marginHorizontal,
    },
    {
      name: "mx4",
      value: styling.mx4.marginHorizontal,
    },
    {
      name: "mx5",
      value: styling.mx5.marginHorizontal,
    },
    {
      name: "ms1",
      value: styling.ms1.marginLeft,
    },
    {
      name: "ms2",
      value: styling.ms2.marginLeft,
    },
    {
      name: "ms3",
      value: styling.ms3.marginLeft,
    },
    {
      name: "ms4",
      value: styling.ms4.marginLeft,
    },
    {
      name: "ms5",
      value: styling.ms5.marginLeft,
    },
    {
      name: "me1",
      value: styling.me1.marginEnd,
    },
    {
      name: "me2",
      value: styling.me2.marginEnd,
    },
    {
      name: "me3",
      value: styling.me3.marginEnd,
    },
    {
      name: "me4",
      value: styling.me4.marginEnd,
    },
    {
      name: "me5",
      value: styling.me5.marginEnd,
    },
    {
      name: "mt1",
      value: styling.mt1.marginTop,
    },
    {
      name: "mt2",
      value: styling.mt2.marginTop,
    },
    {
      name: "mt3",
      value: styling.mt3.marginTop,
    },
    {
      name: "mt4",
      value: styling.mt4.marginTop,
    },
    {
      name: "mt5",
      value: styling.mt5.marginTop,
    },
    {
      name: "mb1",
      value: styling.mb1.marginBottom,
    },
    {
      name: "mb2",
      value: styling.mb2.marginBottom,
    },
    {
      name: "mb3",
      value: styling.mb3.marginBottom,
    },
    {
      name: "mb4",
      value: styling.mb4.marginBottom,
    },
    {
      name: "mb5",
      value: styling.mb5.marginBottom,
    },
  ]);
  // Fatima
  return (
    <>
      <Box>
        <BAContent
          mobileSection={<BAMobileView>
            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                m1
              </Typography>
              <BAMarginCom margin={{ margin: 10 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                m2
              </Typography>
              <BAMarginCom margin={{ margin: 20 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                m3
              </Typography>
              <BAMarginCom margin={{ margin: 30 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                m4
              </Typography>
              <BAMarginCom margin={{ margin: 40 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                m5
              </Typography>
              <BAMarginCom margin={{ margin: 50 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                my1
              </Typography>
              <BAMarginCom margin={{ marginBottom: 10, marginTop: 10 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                my2
              </Typography>
              <BAMarginCom margin={{ marginBottom: 20, marginTop: 20 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                my3
              </Typography>
              <BAMarginCom margin={{ marginBottom: 30, marginTop: 30 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                my4
              </Typography>
              <BAMarginCom margin={{ marginBottom: 40, marginTop: 40 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                my5
              </Typography>
              <BAMarginCom margin={{ marginBottom: 50, marginTop: 50 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mx1
              </Typography>
              <BAMarginCom margin={{ marginLeft: 10, marginRight: 10 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mx2
              </Typography>
              <BAMarginCom margin={{ marginLeft: 20, marginRight: 20 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mx3
              </Typography>
              <BAMarginCom margin={{ marginLeft: 30, marginRight: 30 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mx4
              </Typography>
              <BAMarginCom margin={{ marginLeft: 40, marginRight: 40 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mx5
              </Typography>
              <BAMarginCom margin={{ marginLeft: 50, marginRight: 50 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                ms1
              </Typography>
              <BAMarginCom margin={{ marginLeft: 10 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                ms2
              </Typography>
              <BAMarginCom margin={{ marginLeft: 20 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                ms3
              </Typography>
              <BAMarginCom margin={{ marginLeft: 30 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                ms4
              </Typography>
              <BAMarginCom margin={{ marginLeft: 40 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                ms5
              </Typography>
              <BAMarginCom margin={{ marginLeft: 50 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                me1
              </Typography>
              <BAMarginCom margin={{ marginRight: 10 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                me2
              </Typography>
              <BAMarginCom margin={{ marginRight: 20 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                me3
              </Typography>
              <BAMarginCom margin={{ marginRight: 30 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                me4
              </Typography>
              <BAMarginCom margin={{ marginRight: 40 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                me5
              </Typography>
              <BAMarginCom margin={{ marginRight: 50 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mt1
              </Typography>
              <BAMarginCom margin={{ marginTop: 10 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mt2
              </Typography>
              <BAMarginCom margin={{ marginTop: 20 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mt3
              </Typography>
              <BAMarginCom margin={{ marginTop: 30 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mt4
              </Typography>
              <BAMarginCom margin={{ marginTop: 40 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mt5
              </Typography>
              <BAMarginCom margin={{ marginTop: 50 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mb1
              </Typography>
              <BAMarginCom margin={{ marginBottom: 10 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mb2
              </Typography>
              <BAMarginCom margin={{ marginBottom: 20 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mb3
              </Typography>
              <BAMarginCom margin={{ marginBottom: 30 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mb4
              </Typography>
              <BAMarginCom margin={{ marginBottom: 40 }} />
            </Box>

            <Box className="py-2">
              <Typography className="p-2" variant="h4">
                mb5
              </Typography>
              <BAMarginCom margin={{ marginBottom: 50 }} />
            </Box>
          </BAMobileView>}
          codeSection={
            <Box className="px-2" sx={{ height: 700 }}>
              <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                <BACopyButton code={marginCode} />
                <code>
                  {marginCode}
                </code>
              </pre>
            </Box>
          }
          gridSection={<BAGrid
            datasource={margins}
            gridCols={[
              {
                key: "name",
                label: "Name",
              },
              {
                key: "value",
                label: "Value",
              },
            ]}
          />} heading="Margin">

        </BAContent>
      </Box>
    </>
  );
}
