import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function BAShowError(props) {
    const { open, message, close } = props;
    const [axis, setAxis] = React.useState({
        vertical: 'bottom',
        horizontal: 'center',
    })
    const { vertical, horizontal } = axis;

    // const handleClick = () => {
    //   setOpen(true);
    // };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        close(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={4500}
                onClose={handleClose}
                message={Array.isArray(message) ? message.join(' , ') : message}
                action={action}
                anchorOrigin={{ vertical, horizontal }}
            />
        </div>
    );
}