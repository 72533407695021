import { Box, Typography } from "@mui/material";
import BAContent from "../components/BAContent";
import BAMobileView from "../components/BAMobileView";
import BATxt from "../components/BATxt";
import styling from "../rnTheme/rncstyles";
import BAGrid from "../components/BAGrid";
import { useState } from "react";
import BAShowcolor from "../components/BAShowcolor";
import { textAllignmentCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";

export default function BATextDetails() {
    const [textColorList, setTextColorList] = useState([
        {
            name: 'textStart',
            value: JSON.stringify(styling.textStart)
        },
        {
            name: 'textCenter',
            value: JSON.stringify(styling.textCenter)
        },
        {
            name: 'textEnd',
            value: JSON.stringify(styling.textEnd)
        },
        {
            name: 'textBold',
            value: JSON.stringify(styling.textBold)
        },
        {
            name: 'textUnderline',
            value: JSON.stringify(styling.textBold)
        },
    ])
    return <>
        <BAContent codeSection={
            <Box className='px-2'>
                <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                    <BACopyButton copy={textAllignmentCode} />
                    <code>
                        {textAllignmentCode}
                    </code>
                </pre>
            </Box>
        } gridSection={<BAGrid datasource={textColorList} gridCols={[
            {
                key: 'name',
                label: "Name"
            },
            {
                key: 'value',
                label: "Value"
            },
        ]} />} mobileSection={<BAMobileView>
            <Typography className="mb-2" variant="h4">Alignment</Typography>
            <Typography className="text-start p-2 bg-light my-1">textLeft</Typography>
            <Typography className="text-center p-2 bg-light my-1">textCenter</Typography>
            <Typography className="text-end p-2 bg-light my-1">textRight</Typography>
            <Typography className="fw-bold p-2 bg-light my-1">Bold : textBold</Typography>
            <Typography className="text-decoration-underline p-2 bg-light my-1">Bold : textUnderline</Typography>
        </BAMobileView>} heading="Text">

        </BAContent>
    </>
}