import { Box, Grid, Typography } from "@mui/material";
import Footer from "../webLayout/footer";
import { Helmet } from "react-helmet";
import HeaderNav from "../webLayout/HeaderNav";

export default function AboutUs() {
    return <>
        <Helmet>
            <meta property="og:title" content={"About RNC Styles"} />
            <meta property="og:description" content={"Create stunning mobile user interfaces with ease using RNC Styles – the ultimate solution for React Native developers. Say goodbye to manual styling and hello to a world of pre-built utility styles, all inspired by the best in web design."} />
            <meta property="og:image" content={"https://rncstyles.com/static/media/logo.859b6eba4e978fb4417b.png"} />
            <meta property="og:url" content={"https://rncstyles.com"} />
        </Helmet>
        <Box className='bgLight'>
            <HeaderNav />
            <Box className='homeBody'>
                <Box className='whiteTransparen py-5 text-center'>
                    <Box className='container py-5'>
                        <Grid className="justify-content-center" container>
                            <Grid item md={6} xs={12} sm={12}>
                                <Typography className="mb-4 fw-bold" variant="h4">About Us</Typography>
                                <Typography className="specialText" variant="h4">We're dedicated to simplifying the world of UI design in React Native, making it effortless to create captivating mobile interfaces.</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            <Box className='py-5'>
                <Box className='container'>
                    <Box className='py-3'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='px-3'>
                                    <Typography className='mb-3  fw-bold' variant="h4">About us</Typography>
                                    <Typography variant="h6">At RNC Styles, we're passionate about simplifying the world of React Native UI design. Our mission is to empower developers with an unparalleled styling library, making mobile app design a seamless experience.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className='py-3'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='px-3'>
                                    <Typography className='mb-3  fw-bold' variant="h5">What Drives Us:</Typography>
                                    <Typography >We understand the challenges developers face in crafting beautiful, responsive interfaces. With RNC Styles, we're driven by a commitment to make UI styling a breeze. By providing a comprehensive collection of utility styles inspired by web design best practices, we aim to redefine the way you create mobile applications.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className='py-3'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='px-3'>
                                    <Typography className='mb-3  fw-bold' variant="h5">Our Commitment:</Typography>
                                    <Typography >As we work tirelessly to enhance RNC Styles, we're also actively developing a suite of useful and usable components. Our goal is to create a one-stop solution for all your React Native styling needs.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className='py-3'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='px-3'>
                                    <Typography className='mb-3  fw-bold' variant="h5">Join Us on this Journey:</Typography>
                                    <Typography >We're not just a library; we're a vibrant community of React Native enthusiasts. Connect with us, share your experiences, and help shape the future of mobile app design. Join our community on Discord and stay up to date with the latest developments.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className='py-3'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='px-3'>
                                    <Typography className='mb-3  fw-bold' variant="h5">Your Feedback Matters:</Typography>
                                    <Typography >Your feedback and contributions are invaluable to us. We welcome your ideas and suggestions as we continue to evolve and expand RNC Styles to meet the evolving needs of the React Native development community.</Typography>
                                    <Typography >We're excited to have you on board as we explore new horizons in mobile app design. Thank you for choosing RNC Styles.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <hr />
                    <Typography>
                        Feel free to tailor this "About Us" content to match your brand's voice and goals.
                    </Typography>
                </Box>
            </Box>
            <Footer />
        </Box>
    </>
}