import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Docs from "../pages/docs"
import Home from "../pages/home"
import AboutUs from "../pages/aboutus"
import MsgBoat from "../pages/boat"
import UITemplate from "../pages/templates"
import ComponentsDocs from '../pages/componentsDocs'

export default function AppRouter() {
    return <>
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="docs" element={<Docs />} />
                <Route path="components" element={<ComponentsDocs />} />
                <Route path="aboutus" element={<AboutUs />} />
                <Route path="ai" element={<MsgBoat />} />
                <Route path="uitemplates" element={<UITemplate />} />
            </Routes>
        </Router>
    </>
}