export let loginCode = `
import { View, TextInput, TouchableOpacity, Text } from 'react-native'
import rncStyles from 'rncstyles'

export default function Login() {
    return <>
        <View
            style={[
                rncStyles.h100,
                rncStyles.bgWhite
            ]}>
            <View
                style={[
                    rncStyles.h100,
                    rncStyles.p2,
                    rncStyles.justifyContentCenter
                ]}>
                <View
                    style={rncStyles.py5}>
                    <Text
                        style={[
                            rncStyles.fs1,
                            rncStyles.textPrimary,
                            rncStyles.textBold,
                            rncStyles.mb1
                        ]}
                    >Login</Text>
                    <Text
                        style={[
                            rncStyles.fs5,
                            rncStyles.textSecondary
                        ]}
                    >Please Sign in to continue.</Text>
                </View>
                <View>
                    <View
                        style={rncStyles.pb1}>
                        <Text
                            style={[
                                rncStyles.textPrimary,
                                rncStyles.p1
                            ]}
                        >Email</Text>
                        <TextInput
                            style={[
                                rncStyles.input,
                                rncStyles.bgWhite,
                                rncStyles.rounded,
                                rncStyles.border1,
                                rncStyles.borderPrimary
                            ]}
                            placeholder='example@abc.com'
                        />
                    </View>
                    <View
                        style={rncStyles.pb1}>
                        <Text
                            style={[
                                rncStyles.textPrimary,
                                rncStyles.p1
                            ]}
                        >Password</Text>
                        <TextInput
                            style={[
                                rncStyles.input,
                                rncStyles.bgWhite,
                                rncStyles.rounded,
                                rncStyles.border1,
                                rncStyles.borderPrimary
                            ]}
                            placeholder='Enter Password atleast 6 characters'
                        />
                    </View>
                    <View
                        style={rncStyles.py1}
                    >
                        <TouchableOpacity>
                            <Text
                                style={[
                                    rncStyles.textPrimary,
                                    rncStyles.textBold,
                                    rncStyles.fs5,
                                    rncStyles.textEnd
                                ]}
                            >Forgot Password?</Text>
                        </TouchableOpacity>
                    </View>
                    <View
                        style={rncStyles.py2}
                    >
                        <TouchableOpacity
                            style={[
                                rncStyles.btnPrimary,
                                rncStyles.rounded
                            ]}
                        >
                            <Text
                                style={[
                                    rncStyles.fs5,
                                    rncStyles.textWhite,
                                    rncStyles.textCenter
                                ]}
                            >Login</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View
                    style={[
                        rncStyles.py2,
                        rncStyles.flexRow,
                        rncStyles.justifyContentCenter
                    ]}
                >
                    <Text
                        style={[
                            rncStyles.textBold,
                            rncStyles.textSecondary,
                            rncStyles.fs5
                        ]}
                    >Not Registerar yet?</Text>
                    <TouchableOpacity>
                        <Text
                            style={[
                                rncStyles.textBold,
                                rncStyles.fs5,
                                rncStyles.ms1,
                                rncStyles.textPrimary
                            ]}
                        >Sign Up</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    </>
}

`

export let forgotpasswordscreen = `
import { View, TextInput, TouchableOpacity, Text } from 'react-native'
import rncStyles from 'rncstyles'

export default function ForgotPassword() {
    return <>
        <View
            style={[
                rncStyles.h100,
                rncStyles.bgWhite
            ]}>
            <View
                style={[
                    rncStyles.h100,
                    rncStyles.p2,
                    rncStyles.justifyContentAround,
                ]}>
                <View
                    style={rncStyles.py5}>
                    <Text
                        style={[
                            rncStyles.fs1,
                            rncStyles.textPrimary,
                            rncStyles.textBold,
                            rncStyles.mb1
                        ]}
                    >Forgot Password?</Text>
                    <Text
                        style={[
                            rncStyles.fs5,
                            rncStyles.textSecondary
                        ]}
                    >Create a new Password.</Text>
                </View>
                <View>
                    <View
                        style={rncStyles.pb1}>
                        <Text
                            style={[
                                rncStyles.textPrimary,
                                rncStyles.p1
                            ]}
                        >New Password</Text>
                        <TextInput
                            secureTextEntry={true}
                            style={[
                                rncStyles.input,
                                rncStyles.bgWhite,
                                rncStyles.rounded,
                                rncStyles.border1,
                                rncStyles.borderPrimary
                            ]}
                            placeholder='Enter Password atleast 6 characters'
                        />
                    </View>
                    <View
                        style={rncStyles.pb1}>
                        <Text
                            style={[
                                rncStyles.textPrimary,
                                rncStyles.p1
                            ]}
                        >Retype Password</Text>
                        <TextInput
                            secureTextEntry={true}
                            style={[
                                rncStyles.input,
                                rncStyles.bgWhite,
                                rncStyles.rounded,
                                rncStyles.border1,
                                rncStyles.borderPrimary
                            ]}
                            placeholder='Enter Password atleast 6 characters'
                        />
                    </View>
                    <View
                        style={rncStyles.py2}
                    >
                        <TouchableOpacity
                            style={[
                                rncStyles.btnPrimary,
                                rncStyles.rounded
                            ]}
                        >
                            <Text
                                style={[
                                    rncStyles.fs5,
                                    rncStyles.textWhite,
                                    rncStyles.textCenter
                                ]}
                            >Update Password</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View
                    style={[
                        rncStyles.py2,
                        rncStyles.flexRow,
                        rncStyles.justifyContentCenter
                    ]}
                >

                    <TouchableOpacity>
                        <Text
                            style={[
                                rncStyles.textBold,
                                rncStyles.fs5,
                                rncStyles.ms1,
                                rncStyles.textPrimary
                            ]}
                        >Back To Login</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    </>
}

`

export let signUpScreenCode = `
import { View, TextInput, TouchableOpacity, Text, ScrollView } from 'react-native'
import rncStyles from 'rncstyles'

export default function SignUp() {
    return <>
        <View
            style={[
                rncStyles.h100,
                rncStyles.bgWhite
            ]}>
            <View
                style={[
                    rncStyles.h100,
                    rncStyles.p2,
                    rncStyles.justifyContentCenter
                ]}>
                <View
                    style={rncStyles.py5}>
                    <Text
                        style={[
                            rncStyles.fs1,
                            rncStyles.textPrimary,
                            rncStyles.textBold,
                            rncStyles.mb1
                        ]}
                    >Sign Up</Text>
                    <Text
                        style={[
                            rncStyles.fs5,
                            rncStyles.textSecondary
                        ]}
                    >Please Enter Your Information and create Acount.</Text>
                </View>
                <ScrollView>
                    <View>
                        <View
                            style={rncStyles.pb1}>
                            <Text
                                style={[
                                    rncStyles.textPrimary,
                                    rncStyles.p1
                                ]}
                            >Full Name</Text>
                            <TextInput
                                style={[
                                    rncStyles.input,
                                    rncStyles.bgWhite,
                                    rncStyles.rounded,
                                    rncStyles.border1,
                                    rncStyles.borderPrimary
                                ]}
                                placeholder='eg. Muhammad Zaid'
                            />
                        </View>
                        <View
                            style={rncStyles.pb1}>
                            <Text
                                style={[
                                    rncStyles.textPrimary,
                                    rncStyles.p1
                                ]}
                            >Email</Text>
                            <TextInput
                                keyboardType='email-address'
                                style={[
                                    rncStyles.input,
                                    rncStyles.bgWhite,
                                    rncStyles.rounded,
                                    rncStyles.border1,
                                    rncStyles.borderPrimary
                                ]}
                                placeholder='example@abc.com'
                            />
                        </View>
                        <View
                            style={rncStyles.pb1}>
                            <Text
                                style={[
                                    rncStyles.textPrimary,
                                    rncStyles.p1
                                ]}
                            >Password</Text>
                            <TextInput
                                secureTextEntry={true}
                                style={[
                                    rncStyles.input,
                                    rncStyles.bgWhite,
                                    rncStyles.rounded,
                                    rncStyles.border1,
                                    rncStyles.borderPrimary
                                ]}
                                placeholder='Enter Password atleast 6 characters'
                            />
                        </View>
                        <View
                            style={rncStyles.pb1}>
                            <Text
                                style={[
                                    rncStyles.textPrimary,
                                    rncStyles.p1
                                ]}
                            >Retype Password</Text>
                            <TextInput
                                secureTextEntry={true}
                                style={[
                                    rncStyles.input,
                                    rncStyles.bgWhite,
                                    rncStyles.rounded,
                                    rncStyles.border1,
                                    rncStyles.borderPrimary
                                ]}
                                placeholder='Enter Password atleast 6 characters'
                            />
                        </View>
                        <View
                            style={rncStyles.py2}
                        >
                            <TouchableOpacity
                                style={[
                                    rncStyles.btnPrimary,
                                    rncStyles.rounded
                                ]}
                            >
                                <Text
                                    style={[
                                        rncStyles.fs5,
                                        rncStyles.textWhite,
                                        rncStyles.textCenter
                                    ]}
                                >Sign Up</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
                <View
                    style={[
                        rncStyles.py2,
                        rncStyles.flexRow,
                        rncStyles.justifyContentCenter
                    ]}
                >
                    <Text
                        style={[
                            rncStyles.textBold,
                            rncStyles.textSecondary,
                            rncStyles.fs5
                        ]}
                    >Have an Account?</Text>
                    <TouchableOpacity>
                        <Text
                            style={[
                                rncStyles.textBold,
                                rncStyles.fs5,
                                rncStyles.ms1,
                                rncStyles.textPrimary
                            ]}
                        >Login</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    </>
}

`

export let profileScreenCode = `
import { View, TextInput, TouchableOpacity, Text, ScrollView, Image } from 'react-native'
import rncStyles from 'rncstyles'

export default function Profile() {
    return <>
        <View
            style={[
                rncStyles.h100,
                rncStyles.bgWhite
            ]}>
            <View
                style={[
                    rncStyles.h100,
                    rncStyles.justifyContentCenter
                ]}>
                <ScrollView>
                    <View
                        style={[
                            rncStyles.p2,
                            rncStyles.py5
                        ]}
                    >
                        <View
                            style={rncStyles.flexCenter}>
                            <Image
                                resizeMode='contain'
                                style={[
                                    rncStyles.roundedPill,
                                    { width: 140, height: 140 }
                                ]}
                                source={{
                                    uri: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8xKaQDRmiaG94pbm-BCrgPSZRksQ_BSGxmY3qGxEPI_DYyVLB2QpDM02N81rto21mths&usqp=CAU'
                                }}
                            />
                        </View>
                        <View
                            style={rncStyles.flexCenter}
                        >
                            <Text
                                style={[
                                    rncStyles.fs1,
                                    rncStyles.textPrimary,
                                    rncStyles.textBold,
                                    rncStyles.mb1
                                ]}
                            >Muhammad Zaid</Text>
                            <Text
                                style={[
                                    rncStyles.fs5,
                                    rncStyles.textSecondary,
                                ]}
                            >@Muhammad_zaid</Text>
                            <TouchableOpacity
                                style={[
                                    rncStyles.btnPrimary,
                                    rncStyles.mt1
                                ]}
                            >
                                <Text
                                    style={rncStyles.textWhite}
                                >Edit Profile</Text>
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View
                        style={rncStyles.p2}
                    >
                        <View
                            style={[
                                rncStyles.rounded,
                                rncStyles.shadow5,
                                rncStyles.bgWhite,
                                rncStyles.p1,
                                rncStyles.flexRow
                            ]}
                        >
                            <View
                                style={[
                                    rncStyles.flex1,
                                    rncStyles.p1
                                ]}
                            >
                                <Text
                                    style={[
                                        rncStyles.textPrimary,
                                        rncStyles.textBold,
                                        rncStyles.fs4,
                                        rncStyles.textCenter
                                    ]}
                                >145</Text>
                                <Text
                                    style={[
                                        rncStyles.textSecondary,
                                        rncStyles.textBold,
                                        rncStyles.textCenter
                                    ]}
                                >Designs</Text>
                            </View>
                            <View
                                style={[
                                    rncStyles.flex1,
                                    rncStyles.p1
                                ]}
                            >
                                <Text
                                    style={[
                                        rncStyles.textPrimary,
                                        rncStyles.textBold,
                                        rncStyles.fs4,
                                        rncStyles.textCenter
                                    ]}
                                >32</Text>
                                <Text
                                    style={[
                                        rncStyles.textSecondary,
                                        rncStyles.textBold,
                                        rncStyles.textCenter
                                    ]}
                                >Following</Text>
                            </View>
                            <View
                                style={[
                                    rncStyles.flex1,
                                    rncStyles.p1
                                ]}
                            >
                                <Text
                                    style={[
                                        rncStyles.textPrimary,
                                        rncStyles.textBold,
                                        rncStyles.fs4,
                                        rncStyles.textCenter
                                    ]}
                                >414k</Text>
                                <Text
                                    style={[
                                        rncStyles.textSecondary,
                                        rncStyles.textBold,
                                        rncStyles.textCenter
                                    ]}
                                >Followers</Text>
                            </View>
                        </View>
                    </View>

                    <View
                        style={rncStyles.p2}
                    >
                        <View
                            style={[
                                rncStyles.p1,
                                rncStyles.mb2,
                                rncStyles.borderBottom1,
                                rncStyles.borderLight
                            ]}
                        >
                            <Text
                                style={[
                                    rncStyles.textSecondary,
                                    rncStyles.textBold,
                                ]}
                            >Full Name</Text>
                            <Text
                                style={[
                                    rncStyles.textPrimary,
                                    rncStyles.textBold,
                                    rncStyles.fs3,
                                ]}
                            >Muhammad Zaid</Text>
                        </View>
                        <View
                            style={[
                                rncStyles.p1,
                                rncStyles.mb2,
                                rncStyles.borderBottom1,
                                rncStyles.borderLight
                            ]}
                        >
                            <Text
                                style={[
                                    rncStyles.textSecondary,
                                    rncStyles.textBold,
                                ]}
                            >Email</Text>
                            <Text
                                style={[
                                    rncStyles.textPrimary,
                                    rncStyles.textBold,
                                    rncStyles.fs3,
                                ]}
                            >muhammadzaid@gmail.com</Text>
                        </View>
                        <View
                            style={[
                                rncStyles.p1,
                                rncStyles.mb2,
                                rncStyles.borderBottom1,
                                rncStyles.borderLight
                            ]}
                        >
                            <Text
                                style={[
                                    rncStyles.textSecondary,
                                    rncStyles.textBold,
                                ]}
                            >Gender</Text>
                            <Text
                                style={[
                                    rncStyles.textPrimary,
                                    rncStyles.textBold,
                                    rncStyles.fs3,
                                ]}
                            >Male</Text>
                        </View>
                        <View
                            style={[
                                rncStyles.p1,
                                rncStyles.mb2
                            ]}
                        >
                            <Text
                                style={[
                                    rncStyles.textSecondary,
                                    rncStyles.textBold,
                                ]}
                            >Contact</Text>
                            <Text
                                style={[
                                    rncStyles.textPrimary,
                                    rncStyles.textBold,
                                    rncStyles.fs3,
                                ]}
                            >+92 312 3456789</Text>
                        </View>
                        <View
                            style={rncStyles.py2}
                        >
                            <TouchableOpacity
                                style={[
                                    rncStyles.btnPrimary,
                                    rncStyles.rounded
                                ]}
                            >
                                <Text
                                    style={[
                                        rncStyles.fs5,
                                        rncStyles.textWhite,
                                        rncStyles.textCenter
                                    ]}
                                >Logout</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>

            </View>
        </View>
    </>
}

`

export let editProfileCode = `
import { View, TextInput, TouchableOpacity, Text, ScrollView, Image } from 'react-native'
import rncStyles from 'rncstyles'

export default function EditProfile() {
    return <>
        <View
            style={[
                rncStyles.h100,
                rncStyles.bgWhite
            ]}>
            <View
                style={[
                    rncStyles.h100,
                    rncStyles.p2,
                    rncStyles.justifyContentCenter
                ]}>
                <ScrollView>
                    <View
                        style={rncStyles.py5}>
                        <View
                            style={rncStyles.flexCenter}
                        >
                            <Image
                                resizeMode='contain'
                                style={[
                                    rncStyles.roundedPill,
                                    { width: 140, height: 140 }
                                ]}
                                source={{
                                    uri: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8xKaQDRmiaG94pbm-BCrgPSZRksQ_BSGxmY3qGxEPI_DYyVLB2QpDM02N81rto21mths&usqp=CAU'
                                }}
                            />
                        </View>
                        <View
                            style={rncStyles.flexCenter}
                        >
                            <Text
                                style={[
                                    rncStyles.fs1,
                                    rncStyles.textPrimary,
                                    rncStyles.textBold,
                                    rncStyles.mb1
                                ]}
                            >Muhammad Zaid</Text>
                            <Text
                                style={[
                                    rncStyles.fs5,
                                    rncStyles.textSecondary,
                                ]}
                            >@Muhammad_zaid</Text>
                        </View>
                    </View>

                    <View>
                        <View
                            style={rncStyles.pb1}>
                            <Text
                                style={[
                                    rncStyles.textPrimary,
                                    rncStyles.p1
                                ]}
                            >Full Name</Text>
                            <TextInput
                                style={[
                                    rncStyles.input,
                                    rncStyles.bgWhite,
                                    rncStyles.rounded,
                                    rncStyles.border1,
                                    rncStyles.borderPrimary
                                ]}
                                placeholder='eg. Muhammad Zaid'
                            />
                        </View>

                        <View
                            style={rncStyles.pb1}>
                            <Text
                                style={[
                                    rncStyles.textPrimary,
                                    rncStyles.p1
                                ]}
                            >Contact</Text>
                            <TextInput
                                keyboardType='number-pad'
                                style={[
                                    rncStyles.input,
                                    rncStyles.bgWhite,
                                    rncStyles.rounded,
                                    rncStyles.border1,
                                    rncStyles.borderPrimary
                                ]}
                                placeholder='Enter Password atleast 6 characters'
                            />
                        </View>
                        <View
                            style={rncStyles.py2}
                        >
                            <TouchableOpacity
                                style={[
                                    rncStyles.btnPrimary,
                                    rncStyles.rounded
                                ]}
                            >
                                <Text
                                    style={[
                                        rncStyles.fs5,
                                        rncStyles.textWhite,
                                        rncStyles.textCenter
                                    ]}
                                >Update</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>

            </View>
        </View>
    </>
}

`