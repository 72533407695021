import { Grid, Typography, Box } from "@mui/material";
import BAContent from "../components/BAContent";
import BAMobileView from "../components/BAMobileView";
import styling from "../rnTheme/rncstyles";
import { useState } from "react";
import BAGrid from "../components/BAGrid";
import { borderCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";

export const BABorderComp = ({ borderColor, borderWidth }) => {
    return (
        <Box className='bg-dark' sx={{ width: '100%' }}>
            <Box sx={{ height: 100 }} className='d-flex align-items-center justify-content-center'>

                <Box sx={{ borderColor, borderWidth, borderStyle: 'solid' }} className='bg-dark h-75 w-75'>

                </Box>
            </Box>
        </Box>
    );
}
export const BABorderTopComp = ({ borderTopColor, borderTopWidth }) => {
    return (
        <Box className='bg-dark' sx={{ width: '100%' }}>
            <Box sx={{ height: 100 }} className='d-flex align-items-center justify-content-center'>

                <Box sx={{ borderTopColor, borderTopWidth, borderTopStyle: 'solid' }} className='bg-dark h-75 w-75'>

                </Box>
            </Box>
        </Box>
    );
}
export const BABorderBottomComp = ({ borderBottomColor, borderBottomWidth }) => {
    return (
        <Box className='bg-dark' sx={{ width: '100%' }}>
            <Box sx={{ height: 100 }} className='d-flex align-items-center justify-content-center'>

                <Box sx={{ borderBottomColor, borderBottomWidth, borderBottomStyle: 'solid' }} className='bg-dark h-75 w-75'>

                </Box>
            </Box>
        </Box>
    );
}
export const BABorderLeftComp = ({ borderLeftColor, borderLeftWidth }) => {
    return (
        <Box className='bg-dark' sx={{ width: '100%' }}>
            <Box sx={{ height: 100 }} className='d-flex align-items-center justify-content-center'>

                <Box sx={{ borderLeftColor, borderLeftWidth, borderLeftStyle: 'solid' }} className='bg-dark h-75 w-75'>

                </Box>
            </Box>
        </Box>
    );
}
export const BABorderRightComp = ({ borderRightColor, borderRightWidth }) => {
    return (
        <Box className='bg-dark' sx={{ width: '100%' }}>
            <Box sx={{ height: 100 }} className='d-flex align-items-center justify-content-center'>

                <Box sx={{ borderRightColor, borderRightWidth, borderRightStyle: 'solid' }} className='bg-dark h-75 w-75'>

                </Box>
            </Box>
        </Box>
    );
}



export default function BABorder() {
    // Sumaiya

    let borderStyles = {
        border1: 1,
        border2: 2,
        border3: 3,
        border4: 4,
        border5: 5,
        borderTop1: 1,
        borderTop2: 2,
        borderTop3: 3,
        borderTop4: 4,
        borderTop5: 5,
        borderBottom1: 1,
        borderBottom2: 2,
        borderBottom3: 3,
        borderBottom4: 4,
        borderBottom5: 5,
        borderRight1: 1,
        borderRight2: 2,
        borderRight3: 3,
        borderRight4: 4,
        borderRight5: 5,
        borderLeft1: 1,
        borderLeft2: 2,
        borderLeft3: 3,
        borderLeft4: 4,
        borderLeft5: 5,
    }

    const [border, setBorder] = useState([
        {
            name: 'border1',
            value: borderStyles.border1,
        },
        {
            name: 'border2',
            value: borderStyles.border2,
        },
        {
            name: 'border3',
            value: borderStyles.border3,
        },
        {
            name: 'border4',
            value: borderStyles.border4,
        },
        {
            name: 'border5',
            value: borderStyles.border5,
        },
        {
            name: 'borderTop1',
            value: borderStyles.borderTop1,
        },
        {
            name: 'borderTop2',
            value: borderStyles.borderTop2,
        },
        {
            name: 'borderTop3',
            value: borderStyles.borderTop3,
        },
        {
            name: 'borderTop4',
            value: borderStyles.borderTop4,
        },
        {
            name: 'borderTop5',
            value: borderStyles.borderTop5,
        },
        {
            name: 'borderBottom1',
            value: borderStyles.borderBottom1,
        },
        {
            name: 'borderBottom2',
            value: borderStyles.borderBottom2,
        },
        {
            name: 'borderBottom3',
            value: borderStyles.borderBottom3,
        },
        {
            name: 'borderBottom4',
            value: borderStyles.borderBottom4,
        },
        {
            name: 'borderBottom5',
            value: borderStyles.borderBottom5,
        },
        {
            name: 'borderRight1',
            value: borderStyles.borderRight1,
        },
        {
            name: 'borderRight2',
            value: borderStyles.borderRight2,
        },
        {
            name: 'borderRight3',
            value: borderStyles.borderRight3,
        },
        {
            name: 'borderRight4',
            value: borderStyles.borderRight4,
        },
        {
            name: 'borderRight5',
            value: borderStyles.borderRight5,
        },
        {
            name: 'borderLeft1',
            value: borderStyles.borderLeft1,
        },
        {
            name: 'borderLeft2',
            value: borderStyles.borderLeft2,
        },
        {
            name: 'borderLeft3',
            value: borderStyles.borderLeft3,
        },
        {
            name: 'borderLeft4',
            value: borderStyles.borderLeft4,
        },
        {
            name: 'borderLeft5',
            value: borderStyles.borderLeft5,
        },
        {
            name: 'borderWhite',
            value: "-",
        },
        {
            name: 'borderPrimary',
            value: "-",
        },
        {
            name: 'borderSecondary',
            value: "-",
        },
        {
            name: 'borderSuccess',
            value: "-",
        },
        {
            name: 'borderDanger',
            value: "-",
        },
        {
            name: 'borderWarning',
            value: "-",
        },
        {
            name: 'borderBlack',
            value: "-",
        },
        {
            name: 'borderInfo',
            value: "-",
        },
        {
            name: 'borderLight',
            value: "-",
        },
        {
            name: 'borderTopWhite',
            value: "-",
        },
        {
            name: 'borderTopPrimary',
            value: "-",
        },
        {
            name: 'borderTopSecondary',
            value: "-",
        },
        {
            name: 'borderTopSuccess',
            value: "-",
        },
        {
            name: 'borderTopDanger',
            value: "-",
        },
        {
            name: 'borderTopWarning',
            value: "-",
        },
        {
            name: 'borderTopBlack',
            value: "-",
        },
        {
            name: 'borderTopInfo',
            value: "-",
        },
        {
            name: 'borderTopLight',
            value: "-",
        },
        {
            name: 'borderBottomWhite',
            value: "-",
        },
        {
            name: 'borderBottomPrimary',
            value: "-",
        },
        {
            name: 'borderBottomSecondary',
            value: "-",
        },
        {
            name: 'borderBottomSuccess',
            value: "-",
        },
        {
            name: 'borderBottomDanger',
            value: "-",
        },
        {
            name: 'borderBottomWarning',
            value: "-",
        },
        {
            name: 'borderBottomBlack',
            value: "-",
        },
        {
            name: 'borderBottomInfo',
            value: "-",
        },
        {
            name: 'borderBottomLight',
            value: "-",
        },
        {
            name: 'borderLeftWhite',
            value: "-",
        },
        {
            name: 'borderLeftPrimary',
            value: "-",
        },
        {
            name: 'borderLeftSecondary',
            value: "-",
        },
        {
            name: 'borderLeftSuccess',
            value: "-",
        },
        {
            name: 'borderLeftDanger',
            value: "-",
        },
        {
            name: 'borderLeftWarning',
            value: "-",
        },
        {
            name: 'borderLeftBlack',
            value: "-",
        },
        {
            name: 'borderLeftInfo',
            value: "-",
        },
        {
            name: 'borderLeftLight',
            value: "-",
        },
        {
            name: 'borderRightWhite',
            value: "-",
        },
        {
            name: 'borderRightPrimary',
            value: "-",
        },
        {
            name: 'borderRightSecondary',
            value: "-",
        },
        {
            name: 'borderRightSuccess',
            value: "-",
        },
        {
            name: 'borderRightDanger',
            value: "-",
        },
        {
            name: 'borderRightWarning',
            value: "-",
        },
        {
            name: 'borderRightBlack',
            value: "-",
        },
        {
            name: 'borderRightInfo',
            value: "-",
        },
        {
            name: 'borderRightLight',
            value: "-",
        },

    ])

    return <>
        <Box>
            <BAContent mobileSection={<BAMobileView>
                <Typography className="p-2 text-decoration-underline" variant="h5">Border Width</Typography>
                <Typography className="p-2" variant="h6">border1</Typography>
                <BABorderComp borderWidth={1} borderColor={'white'} />
                <Typography className="p-2" variant="h6">border2</Typography>
                <BABorderComp borderWidth={2} borderColor={'white'} />
                <Typography className="p-2" variant="h6">border3</Typography>
                <BABorderComp borderWidth={3} borderColor={'white'} />
                <Typography className="p-2" variant="h6">border4</Typography>
                <BABorderComp borderWidth={4} borderColor={'white'} />
                <Typography className="p-2" variant="h6">border5</Typography>
                <BABorderComp borderWidth={5} borderColor={'white'} />

                <Typography className="p-2 text-decoration-underline" variant="h5">Border Top Width</Typography>
                <Typography className="p-2" variant="h6">borderTop1</Typography>
                <BABorderTopComp borderTopWidth={1} borderTopColor={'white'} />
                <Typography className="p-2" variant="h6">borderTop2</Typography>
                <BABorderTopComp borderTopWidth={2} borderTopColor={'white'} />
                <Typography className="p-2" variant="h6">borderTop3</Typography>
                <BABorderTopComp borderTopWidth={3} borderTopColor={'white'} />
                <Typography className="p-2" variant="h6">borderTop4</Typography>
                <BABorderTopComp borderTopWidth={4} borderTopColor={'white'} />
                <Typography className="p-2" variant="h6">borderTop5</Typography>
                <BABorderTopComp borderTopWidth={5} borderTopColor={'white'} />

                <Typography className="p-2 text-decoration-underline" variant="h5">Border Bottom Width</Typography>
                <Typography className="p-2" variant="h6">borderBottom1</Typography>
                <BABorderBottomComp borderBottomWidth={1} borderBottomColor={'white'} />
                <Typography className="p-2" variant="h6">borderBottom2</Typography>
                <BABorderBottomComp borderBottomWidth={2} borderBottomColor={'white'} />
                <Typography className="p-2" variant="h6">borderBottom3</Typography>
                <BABorderBottomComp borderBottomWidth={3} borderBottomColor={'white'} />
                <Typography className="p-2" variant="h6">borderBottom4</Typography>
                <BABorderBottomComp borderBottomWidth={4} borderBottomColor={'white'} />
                <Typography className="p-2" variant="h6">borderBottom5</Typography>
                <BABorderBottomComp borderBottomWidth={5} borderBottomColor={'white'} />

                <Typography className="p-2 text-decoration-underline" variant="h5">Border Left Width</Typography>
                <Typography className="p-2" variant="h6">borderLeft1</Typography>
                <BABorderLeftComp borderLeftWidth={1} borderLeftColor={'white'} />
                <Typography className="p-2" variant="h6">borderLeft2</Typography>
                <BABorderLeftComp borderLeftWidth={2} borderLeftColor={'white'} />
                <Typography className="p-2" variant="h6">borderLeft3</Typography>
                <BABorderLeftComp borderLeftWidth={3} borderLeftColor={'white'} />
                <Typography className="p-2" variant="h6">borderLeft4</Typography>
                <BABorderLeftComp borderLeftWidth={4} borderLeftColor={'white'} />
                <Typography className="p-2" variant="h6">borderLeft5</Typography>
                <BABorderLeftComp borderLeftWidth={5} borderLeftColor={'white'} />


                <Typography className="p-2 text-decoration-underline" variant="h5">Border Right Width</Typography>
                <Typography className="p-2" variant="h6">borderRight1</Typography>
                <BABorderRightComp borderRightWidth={1} borderRightColor={'white'} />
                <Typography className="p-2" variant="h6">borderRight2</Typography>
                <BABorderRightComp borderRightWidth={2} borderRightColor={'white'} />
                <Typography className="p-2" variant="h6">borderRight3</Typography>
                <BABorderRightComp borderRightWidth={3} borderRightColor={'white'} />
                <Typography className="p-2" variant="h6">borderRight4</Typography>
                <BABorderRightComp borderRightWidth={4} borderRightColor={'white'} />
                <Typography className="p-2" variant="h6">borderRight5</Typography>
                <BABorderRightComp borderRightWidth={5} borderRightColor={'white'} />


                <Typography className="p-2 text-decoration-underline" variant="h5">Border Color</Typography>
                <Typography className="p-2" variant="h6">borderWhite</Typography>
                <BABorderComp borderColor={styling.borderWhite} borderWidth={5} />
                <Typography className="p-2" variant="h6">borderPrimary</Typography>
                <BABorderComp borderColor={styling.borderPrimary} borderWidth={5} />
                <Typography className="p-2" variant="h6">borderSecondary</Typography>
                <BABorderComp borderColor={styling.borderSecondary} borderWidth={5} />
                <Typography className="p-2" variant="h6">borderSuccess</Typography>
                <BABorderComp borderColor={styling.borderSuccess} borderWidth={5} />
                <Typography className="p-2" variant="h6">borderDanger</Typography>
                <BABorderComp borderColor={styling.borderDanger} borderWidth={5} />
                <Typography className="p-2" variant="h6">borderWarning</Typography>
                <BABorderComp borderColor={styling.borderWarning} borderWidth={5} />
                <Typography className="p-2" variant="h6">borderBlack</Typography>
                <BABorderComp borderColor={styling.borderBlack} borderWidth={5} />
                <Typography className="p-2" variant="h6">borderInfo</Typography>
                <BABorderComp borderColor={styling.borderInfo} borderWidth={5} />
                <Typography className="p-2" variant="h6">borderLight</Typography>
                <BABorderComp borderColor={styling.borderLight} borderWidth={5} />

                <Typography className="p-2 text-decoration-underline" variant="h5">Border Top Color</Typography>

                <Typography className="p-2" variant="h6">borderTopWhite</Typography>
                <BABorderTopComp borderTopColor={styling.borderTopWhite} borderTopWidth={5} />
                <Typography className="p-2" variant="h6">borderTopPrimary</Typography>
                <BABorderTopComp borderTopColor={styling.borderTopPrimary} borderTopWidth={5} />
                <Typography className="p-2" variant="h6">borderTopSecondary</Typography>
                <BABorderTopComp borderTopColor={styling.borderTopSecondary} borderTopWidth={5} />
                <Typography className="p-2" variant="h6">borderTopSuccess</Typography>
                <BABorderTopComp borderTopColor={styling.borderTopSuccess} borderTopWidth={5} />
                <Typography className="p-2" variant="h6">borderTopDanger</Typography>
                <BABorderTopComp borderTopColor={styling.borderTopDanger} borderTopWidth={5} />
                <Typography className="p-2" variant="h6">borderTopWarning</Typography>
                <BABorderTopComp borderTopColor={styling.borderTopWarning} borderTopWidth={5} />
                <Typography className="p-2" variant="h6">borderTopBlack</Typography>
                <BABorderTopComp borderTopColor={styling.borderTopBlack} borderTopWidth={5} />
                <Typography className="p-2" variant="h6">borderTopInfo</Typography>
                <BABorderTopComp borderTopColor={styling.borderTopInfo} borderTopWidth={5} />
                <Typography className="p-2" variant="h6">borderTopLight</Typography>
                <BABorderTopComp borderTopColor={styling.borderTopLight} borderTopWidth={5} />

                <Typography className="p-2 text-decoration-underline" variant="h5">Border Bottom Color</Typography>

                <Typography className="p-2" variant="h6">borderBottomWhite</Typography>
                <BABorderBottomComp borderBottomColor={styling.borderBottomWhite} borderBottomWidth={5} />
                <Typography className="p-2" variant="h6">borderBottomPrimary</Typography>
                <BABorderBottomComp borderBottomColor={styling.borderBottomPrimary} borderBottomWidth={5} />
                <Typography className="p-2" variant="h6">borderBottomSecondary</Typography>
                <BABorderBottomComp borderBottomColor={styling.borderBottomSecondary} borderBottomWidth={5} />
                <Typography className="p-2" variant="h6">borderBottomSuccess</Typography>
                <BABorderBottomComp borderBottomColor={styling.borderBottomSuccess} borderBottomWidth={5} />
                <Typography className="p-2" variant="h6">borderBottomDanger</Typography>
                <BABorderBottomComp borderBottomColor={styling.borderBottomDanger} borderBottomWidth={5} />
                <Typography className="p-2" variant="h6">borderBottomWarning</Typography>
                <BABorderBottomComp borderBottomColor={styling.borderBottomWarning} borderBottomWidth={5} />
                <Typography className="p-2" variant="h6">borderBottomBlack</Typography>
                <BABorderBottomComp borderBottomColor={styling.borderBottomBlack} borderBottomWidth={5} />
                <Typography className="p-2" variant="h6">borderBottomInfo</Typography>
                <BABorderBottomComp borderBottomColor={styling.borderBottomInfo} borderBottomWidth={5} />
                <Typography className="p-2" variant="h6">borderBottomLight</Typography>
                <BABorderBottomComp borderBottomColor={styling.borderBottomLight} borderBottomWidth={5} />

                <Typography className="p-2 text-decoration-underline" variant="h5">Border Left Color</Typography>

                <Typography className="p-2" variant="h6">borderLeftWhite</Typography>
                <BABorderLeftComp borderLeftColor={styling.borderLeftWhite} borderLeftWidth={5} />
                <Typography className="p-2" variant="h6">borderLeftPrimary</Typography>
                <BABorderLeftComp borderLeftColor={styling.borderLeftPrimary} borderLeftWidth={5} />
                <Typography className="p-2" variant="h6">borderLeftSecondary</Typography>
                <BABorderLeftComp borderLeftColor={styling.borderLeftSecondary} borderLeftWidth={5} />
                <Typography className="p-2" variant="h6">borderLeftSuccess</Typography>
                <BABorderLeftComp borderLeftColor={styling.borderLeftSuccess} borderLeftWidth={5} />
                <Typography className="p-2" variant="h6">borderLeftDanger</Typography>
                <BABorderLeftComp borderLeftColor={styling.borderLeftDanger} borderLeftWidth={5} />
                <Typography className="p-2" variant="h6">borderLeftWarning</Typography>
                <BABorderLeftComp borderLeftColor={styling.borderLeftWarning} borderLeftWidth={5} />
                <Typography className="p-2" variant="h6">borderLeftBlack</Typography>
                <BABorderLeftComp borderLeftColor={styling.borderLeftBlack} borderLeftWidth={5} />
                <Typography className="p-2" variant="h6">borderLeftInfo</Typography>
                <BABorderLeftComp borderLeftColor={styling.borderLeftInfo} borderLeftWidth={5} />
                <Typography className="p-2" variant="h6">borderLeftLight</Typography>
                <BABorderLeftComp borderLeftColor={styling.borderLeftLight} borderLeftWidth={5} />


                <Typography className="p-2 text-decoration-underline" variant="h5">Border Right Color</Typography>

                <Typography className="p-2" variant="h6">borderRightWhite</Typography>
                <BABorderRightComp borderRightColor={styling.borderRightWhite} borderRightWidth={5} />
                <Typography className="p-2" variant="h6">borderRightPrimary</Typography>
                <BABorderRightComp borderRightColor={styling.borderRightPrimary} borderRightWidth={5} />
                <Typography className="p-2" variant="h6">borderRightSecondary</Typography>
                <BABorderRightComp borderRightColor={styling.borderRightSecondary} borderRightWidth={5} />
                <Typography className="p-2" variant="h6">borderRightSuccess</Typography>
                <BABorderRightComp borderRightColor={styling.borderRightSuccess} borderRightWidth={5} />
                <Typography className="p-2" variant="h6">borderRightDanger</Typography>
                <BABorderRightComp borderRightColor={styling.borderRightDanger} borderRightWidth={5} />
                <Typography className="p-2" variant="h6">borderRightWarning</Typography>
                <BABorderRightComp borderRightColor={styling.borderRightWarning} borderRightWidth={5} />
                <Typography className="p-2" variant="h6">borderRightBlack</Typography>
                <BABorderRightComp borderRightColor={styling.borderRightBlack} borderRightWidth={5} />
                <Typography className="p-2" variant="h6">borderRightInfo</Typography>
                <BABorderRightComp borderRightColor={styling.borderRightInfo} borderRightWidth={5} />
                <Typography className="p-2" variant="h6">borderRightLight</Typography>
                <BABorderRightComp borderRightColor={styling.borderRightLight} borderRightWidth={5} />

            </BAMobileView>} codeSection={
                <Box className='px-2' sx={{ height: 700 }}>
                    <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                        <BACopyButton code={borderCode} />
                        <code>
                            {borderCode}
                        </code>
                    </pre>
                </Box>
            } gridSection={<BAGrid datasource={border} gridCols={[
                {
                    key: 'name',
                    label: "Name"
                },
                {
                    key: 'value',
                    label: "Value"
                },
            ]} />} heading="Border">

            </BAContent>
        </Box>
    </>
}