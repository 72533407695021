import { Box, Grid, Typography } from "@mui/material";
import Footer from "../webLayout/footer";
import BAMobileView from "../components/BAMobileView";
import LoginScreen from '../assets/images/login.jpg';
import ForgotPasswordScreen from '../assets/images/forgotpassword.jpg';
import SignUp from '../assets/images/signup.jpg';
import ProfileScreen from '../assets/images/profile.jpg';
import EditProfile from '../assets/images/editprofile.jpg'
import BACopyButton from "../components/BACopyButton";
import { editProfileCode, forgotpasswordscreen, loginCode, profileScreenCode, signUpScreenCode } from "../config/templatecode";
import { Helmet } from "react-helmet";
import HeaderNav from "../webLayout/HeaderNav";


export default function UITemplate() {
    return <>
        <Helmet>
            <meta property="og:title" content={"RNC Templates"} />
            <meta property="og:description" content={"Create stunning mobile user interfaces with ease using RNC Styles – the ultimate solution for React Native developers. Say goodbye to manual styling and hello to a world of pre-built utility styles, all inspired by the best in web design."} />
            <meta property="og:image" content={"https://rncstyles.com/static/media/logo.859b6eba4e978fb4417b.png"} />
            <meta property="og:url" content={"https://rncstyles.com"} />
        </Helmet>
        <Box className='bgLight'>
            <HeaderNav />
            <Box className='homeBody'>
                <Box className='whiteTransparen py-5 text-center'>
                    <Box className='container py-5'>
                        <Grid className="justify-content-center" container>
                            <Grid item md={6} xs={12} sm={12}>
                                <Typography className="mb-4 fw-bold" variant="h4">Templates</Typography>
                                <Typography className="specialText" variant="h4">Discover Our Mobile App Screens. Here are some Usage of RNC Styles in Real World Applications. which shows how RNC Styles makes coding so much easier.</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            <Box className='py-5'>
                <Box className='container'>
                    <Box className='py-3'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={8}>
                                <Box className='px-3'>
                                    <Typography className='mb-3  fw-bold' variant="h4">Templates</Typography>
                                    <Typography variant="h6">Discover how RNC Styles revolutionizes the world of React Native app development by simplifying the coding process. Step into a realm where creating elegant mobile app interfaces is a breeze. Dive into real-world applications that showcase the ease and efficiency of RNC Styles, and witness firsthand how this styling library streamlines your development journey. Say goodbye to the complexities of manual styling and hello to an era of coding made remarkably easy. Explore, implement, and transform your projects with RNC Styles – where design meets simplicity.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className='py-3'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='px-3'>
                                    <Typography className='mb-3  fw-bold' variant="h5">Profile Screen</Typography>
                                    <Box sx={{ height: 630 }}>
                                        <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                                            <BACopyButton code={profileScreenCode} />
                                            <code>
                                                {profileScreenCode}
                                            </code>
                                        </pre>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='p-3 bg-white custom-rounded shadow d-flex justify-content-center'>
                                    <BAMobileView>
                                        <img width='100%' src={ProfileScreen} alt="Profile Screen" />
                                    </BAMobileView>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                    <Box className='py-3'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='p-3 bg-white custom-rounded shadow d-flex justify-content-center'>
                                    <BAMobileView>
                                        <img width='100%' src={EditProfile} alt="Edit Profile Screen" />
                                    </BAMobileView>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='px-3'>
                                    <Typography className='mb-3  fw-bold' variant="h5">Edit Profile Screen</Typography>
                                    <Box sx={{ height: 630 }}>
                                        <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                                            <BACopyButton code={editProfileCode} />
                                            <code>
                                                {editProfileCode}
                                            </code>
                                        </pre>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className='py-3'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='px-3'>
                                    <Typography className='mb-3  fw-bold' variant="h5">Sign Up Screen</Typography>
                                    <Box sx={{ height: 630 }}>
                                        <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                                            <BACopyButton code={signUpScreenCode} />
                                            <code>
                                                {signUpScreenCode}
                                            </code>
                                        </pre>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='p-3 bg-white custom-rounded shadow d-flex justify-content-center'>
                                    <BAMobileView>
                                        <img width='100%' src={SignUp} alt="Sign Up Screen" />
                                    </BAMobileView>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                    <Box className='py-3'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='p-3 bg-white custom-rounded shadow d-flex justify-content-center'>
                                    <BAMobileView>
                                        <img width='100%' src={LoginScreen} alt="Login Screen" />
                                    </BAMobileView>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='px-3'>
                                    <Typography className='mb-3  fw-bold' variant="h5">Login Screen</Typography>
                                    <Box sx={{ height: 630 }}>
                                        <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                                            <BACopyButton code={loginCode} />
                                            <code>
                                                {loginCode}
                                            </code>
                                        </pre>
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                    <Box className='py-3'>
                        <Grid container>

                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='px-3'>
                                    <Typography className='mb-3  fw-bold' variant="h5">Forgot Password Screen</Typography>
                                    <Box sx={{ height: 630 }}>
                                        <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                                            <BACopyButton code={forgotpasswordscreen} />
                                            <code>
                                                {forgotpasswordscreen}
                                            </code>
                                        </pre>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className='p-3 bg-white custom-rounded shadow d-flex justify-content-center'>
                                    <BAMobileView>
                                        <img width='100%' src={ForgotPasswordScreen} alt="Forgot Password Screen" />
                                    </BAMobileView>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>




                </Box>
            </Box>

        </Box>
        <Footer />
    </>
} 