import { Box, Typography } from "@mui/material";
import BAContent from "../components/BAContent";
import BAMobileView from "../components/BAMobileView";
import { useState } from "react";
import BAGrid from "../components/BAGrid";
import styling from "../rnTheme/rncstyles";
import { borderRadiusCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";

function BABorderRadiusComp({ borderRadius }) {
    return (
        <>
            <Box className='bg-light' sx={{ width: '100%' }}>
                <Box sx={{ height: 100 }} className='d-flex align-items-center justify-content-center'>

                    <Box sx={{ borderColor: '#011627', backgroundColor: '#2EC4B6', borderWidth: 3, borderStyle: 'solid', borderRadius }}
                        className=' h-75 w-75'>

                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default function BABorderRadius() {
    const [borderRadius, setBorderRadius] = useState([
        {
            name: 'rounded',
            value: ''
        },
        {
            name: 'roundedPill',
            value: ''
        },
        {
            name: 'roundedTopRight',
            value: ''
        },
        {
            name: 'roundedTopLeft',
            value: ''
        },
        {
            name: 'roundedBottomRight',
            value: ''
        },
        {
            name: 'roundedBottomLeft',
            value: ''
        },
    ]);
    return (
        <>
            <Box>
                <BAContent
                    heading="Border Radius"
                    mobileSection={
                        <BAMobileView>
                            <Typography className="p-2 text-decoration-underline" variant="h5">Border Radius</Typography>
                            <Typography className="p-2" variant="h6">rounded</Typography>
                            <BABorderRadiusComp borderRadius={styling.rounded} />
                            <Typography className="p-2" variant="h6">roundedPill</Typography>
                            <BABorderRadiusComp borderRadius={styling.roundedPill} />
                            <Typography className="p-2 text-decoration-underline" variant="h5">TopRight</Typography>
                            <Typography className="p-2" variant="h6">rounded</Typography>
                            <BABorderRadiusComp borderRadius={`0px 15px 0px 0px`} />
                            <Typography className="p-2 text-decoration-underline" variant="h5">TopLeft</Typography>
                            <Typography className="p-2" variant="h6">rounded</Typography>
                            <BABorderRadiusComp borderRadius={`15px 0px 0px 0px`} />
                            <Typography className="p-2 text-decoration-underline" variant="h5">BottomRight</Typography>
                            <Typography className="p-2" variant="h6">rounded</Typography>
                            <BABorderRadiusComp borderRadius={`0px 0px 15px 0px`} />
                            <Typography className="p-2 text-decoration-underline" variant="h5">BottomLeft</Typography>
                            <Typography className="p-2" variant="h6">rounded</Typography>
                            <BABorderRadiusComp borderRadius={`0px 0px 0px 15px`} />
                        </BAMobileView>}

                    codeSection={
                        <Box className="px-2" sx={{ height: 700 }}>
                            <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                                <BACopyButton code={borderRadiusCode} />
                                <code>
                                    {borderRadiusCode}
                                </code>
                            </pre>
                        </Box>
                    }

                    gridSection={
                        <BAGrid
                            datasource={borderRadius}
                            gridCols={[
                                {
                                    key: "name",
                                    label: "Name",
                                },
                                {
                                    key: "value",
                                    label: "Value",
                                },
                            ]}
                        />}

                />
            </Box>
        </>
    );
}
