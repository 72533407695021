import { useState } from "react";
import BAContent from "../components/BAContent";
import BAGrid from "../components/BAGrid";
import BAMobileView from "../components/BAMobileView";
import BAFontSize from "../components/BAFontSize";
import styling from "../rnTheme/rncstyles";
import { fontSizeCode } from "../config/showCode";
import { Box } from "@mui/material";
import BACopyButton from "../components/BACopyButton";

export default function BAFontSizeDetails() {

    const [fontData, setFontData] = useState([
        {
            name: 'fsXL',
            value: JSON.stringify({ fontSize: 50 })
        },
        {
            name: 'fsL',
            value: JSON.stringify({ fontSize: 40 })
        },
        {
            name: 'fs1',
            value: JSON.stringify(styling.fs1)
        },
        {
            name: 'fs2',
            value: JSON.stringify(styling.fs2)
        },
        {
            name: 'fs3',
            value: JSON.stringify(styling.fs3)
        },
        {
            name: 'fs4',
            value: JSON.stringify(styling.fs4)
        },
        {
            name: 'fs5',
            value: JSON.stringify(styling.fs5)
        },
        {
            name: 'fs6',
            value: JSON.stringify(styling.fs6)
        },
    ])

    return <>
        <BAContent
            codeSection={
                <Box className='px-2'>
                    <pre className="bg-dark h-100 text-white p-2 shadow rounded">
                        <BACopyButton code={fontSizeCode} />
                        <code>
                            {fontSizeCode}
                        </code>
                    </pre>
                </Box>
            }
            gridSection={<BAGrid datasource={fontData} gridCols={[
                {
                    key: 'name',
                    label: "Name"
                },
                {
                    key: 'value',
                    label: "Value"
                },
            ]} />} mobileSection={<BAMobileView>
                <BAFontSize size={{ fontSize: 50 }} text="fsXL" />
                <BAFontSize size={{ fontSize: 40 }} text="fsL" />
                <BAFontSize size={styling.fs1} text="fs1" />
                <BAFontSize size={styling.fs2} text="fs2" />
                <BAFontSize size={styling.fs3} text="fs3" />
                <BAFontSize size={styling.fs4} text="fs4" />
                <BAFontSize size={styling.fs5} text="fs5" />
                <BAFontSize size={styling.fs6} text="fs6" />

            </BAMobileView>} heading="Font Size" />
    </>
}