import { Box, Container, Grid, Tooltip, Typography } from "@mui/material";
import BAButton from "../components/BAButton";
import GitHubIcon from '@mui/icons-material/GitHub';
import { Link, useNavigate } from "react-router-dom";
import logo from '../assets/images/logo.png'
import ba from '../assets/images/ba.png';
import female from '../assets/images/female.png'
import arsalan from '../assets/images/arsalan.png'
import hamza from '../assets/images/hamza.jpg'
import { useState } from "react";
import BAIconbutton from "../components/BAIconbutton";
import BACopyButton from "../components/BACopyButton";
import Footer from "../webLayout/footer";
import { Helmet } from 'react-helmet';
import mob from '../assets/images/mob.gif'



export default function Home() {

    const [teamMembers, setTeamMembers] = useState([
        {
            name: 'Rabia Azhar',
            githubUrl: 'https://github.com/RabiaSid',
            gender: 'female',
            githubUserName: 'RabiaSid',
        },
        {
            name: 'Sumaiya Moon',
            githubUrl: 'https://github.com/SumaiyaMoon',
            gender: 'female',
            githubUserName: 'SumaiyaMoon',
        },
        {
            name: 'Fatima Naveed',
            githubUrl: 'https://github.com/FatimaPaul',
            gender: 'female',
            githubUserName: 'FatimaPaul',
        },
        {
            name: 'Arsalan Sohail',
            githubUrl: 'https://github.com/marsalansohail123',
            gender: 'male',
            githubUserName: 'marsalansohail123',
            url: arsalan
        },
        {
            name: 'Hamza Kamelen',
            githubUrl: 'https://github.com/hamzakamelen',
            githubUserName: 'hamzakamelen',
            gender: 'male',
            url: hamza
        },
    ])

    const commandLine = 'npm i rncstyles'


    const visitLink = (url) => {
        window.open(url, '_blank');
    }


    const navigate = useNavigate()
    return <>
        <Box>
            <Helmet>
                <meta property="og:title" content={"RNC Styles"} />
                <meta property="og:description" content={"Create stunning mobile user interfaces with ease using RNC Styles – the ultimate solution for React Native developers. Say goodbye to manual styling and hello to a world of pre-built utility styles, all inspired by the best in web design."} />
                <meta property="og:image" content={"https://rncstyles.com/static/media/logo.859b6eba4e978fb4417b.png"} />
                <meta property="og:url" content={"https://rncstyles.com"} />
            </Helmet>
            <Box className='homeBody'>
                <Box className='whiteTransparen h-100vh d-flex justify-content-center align-items-center'>

                    <Box className='p-3'>
                        <Box className='p-5 text-center container'>
                            <img width="200" src={logo} alt="RNC Styles" />
                            <Typography className="text-center" variant="h2">RNC Style</Typography>
                            <Typography className="text-center" variant="h4">React Native Custom Styles</Typography>
                        </Box>
                        <Box className='text-center'>
                            <pre className="bg-light rounded shadow p-2 fs-4">
                                <BACopyButton code={commandLine} />
                                <code>
                                    {commandLine}
                                </code>
                            </pre>
                            <Link to='/docs' className="btn bgPrimary text-white rounded-pill px-4 shadow">Get Started</Link>
                            <Link to='/uitemplates' className="btn bgPrimary text-white rounded-pill px-4 shadow ms-2">Templates</Link>
                        </Box>
                    </Box>

                </Box>
            </Box>
            <Box className='bgLight py-5 d-flex flex-column justify-content-center align-items-center'>
                <Container>
                    <Grid container>
                        <Grid xs={12} sm={12} md={6}>
                            <Box className='p-4 h-100 d-flex justify-content-center flex-column'>
                                <Typography className="textPrimary mb-3" variant="h3">Easy To Use</Typography>
                                <Typography variant="h5">RNC Styles is your gateway to effortless React Native styling. We've carefully crafted a user-friendly experience that simplifies the process of designing captivating mobile interfaces. With an array of pre-built utility styles and an intuitive approach, RNC Styles makes it easy for developers, whether seasoned professionals or newcomers, to achieve polished designs with minimal effort. Say goodbye to the complexities of manual styling; RNC Styles is here to make UI design a breeze.</Typography>
                                <Box>
                                    <Link className="textPrimary btn bgPrimary text-white rounded-pill px-4 shadow mt-4" to='/docs'>Read The Docs</Link>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={12} md={6}>
                            <Box className='p-4 text-center'>
                                <img width='70%' src={mob} alt="" />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className='bgLight py-5 d-flex flex-column justify-content-center align-items-center'>
                <Box className='pb-5'>
                    <Box className='py-5 text-center'>
                        <img className="rounded-pill shadow" width="200" height="200" src={ba} alt="Basit Ahmed" />
                        <Typography className="text-center pt-3" variant="h3">Basit Ahmed</Typography>
                        <Typography className="text-center" variant="h5">Creator of RNC Style</Typography>
                    </Box>
                    <Box className="text-center">
                        <BAButton onClick={() => visitLink('https://github.com/BasitAyaz')} startIcon={<GitHubIcon />} label="BasitAyaz" />
                    </Box>
                </Box>
                <Container>
                    <Typography variant="h4" className="py-4 fw-bold">Our Team</Typography>
                    <Grid spacing={2} container>
                        {teamMembers.map((x, i) => <Grid item md={3} xs={12} sm={12}>
                            <Box className='p-2 py-5 bg-white shadow custom-rounded text-center'>
                                <img className="rounded-pill shadow" width="100" height="100" src={x.gender === 'female' ? female : x.url} alt={x.name} />
                                <Box className='p-2'>
                                    <Typography variant="h5" className="py-2">{x.name}</Typography>
                                    <Tooltip title={x.githubUserName}>
                                        <BAIconbutton onClick={() => visitLink(x.githubUrl)} icon={<GitHubIcon />} />
                                    </Tooltip>
                                    {/* <BAButton onClick={() => visitLink(x.githubUrl)} startIcon={<GitHubIcon />} label={x.githubUserName} /> */}
                                </Box>
                            </Box>
                        </Grid>)}

                    </Grid>
                </Container>
            </Box>
            <Footer />
        </Box>
    </>
}