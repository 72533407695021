import { useState } from "react";
import BAContent from "../components/BAContent";
import styling from "../rnTheme/rncstyles";
import BAGrid from "../components/BAGrid";
import { Box, Grid, Typography } from "@mui/material";
import BAMobileView from "../components/BAMobileView";
import { flexCode } from "../config/showCode";
import BACopyButton from "../components/BACopyButton";

function BAFlexComp({
  flexDirection,
  justifyContent,
  alignItems,
  width,
  flexWrap,
}) {
  return (
    <>
      <Box
        className="bg-light border border-black rounded p-2"
        sx={{
          // width: "100%",
          height: "18vh",
          display: "flex",
          flexDirection,
          justifyContent,
          alignItems,
          width,
          flexWrap,
        }}
      >
        <Box
          className="shadow custom-rounded me-2 mb-2"
          sx={{ width: 30, height: 30, backgroundColor: styling._danger }}
        ></Box>

        <Box
          className="shadow custom-rounded me-2 mb-2"
          sx={{ width: 30, height: 30, backgroundColor: styling._info }}
        ></Box>

        <Box
          className="shadow custom-rounded me-2 mb-2"
          sx={{ width: 30, height: 30, backgroundColor: styling._success }}
        ></Box>
      </Box>
    </>
  );
}

export default function BAFlex() {
  const [flex, setFlex] = useState([
    {
      name: "flexRow",
      value: styling.flexRow.flexDirection,
    },
    {
      name: "flexColumn",
      value: styling.flexColumn.flexDirection,
    },
    {
      name: "flexCenter",
      value: styling.flexCenter.justifyContent,
    },
    {
      name: "flexWrap",
      value: styling.flexWrap.flexWrap,
    },
    {
      name: "flexNoWrap",
      value: styling.flexNoWrap.flexWrap,
    },
    {
      name: "justifyContentCenter",
      value: styling.justifyContentCenter.justifyContent,
    },
    {
      name: "justifyContentStart",
      value: styling.justifyContentStart.justifyContent,
    },
    {
      name: "justifyContentEnd",
      value: styling.justifyContentEnd.justifyContent,
    },
    {
      name: "alignItemsCenter",
      value: styling.alignItemsCenter.alignItems,
    },
    {
      name: "alignItemsStart",
      value: styling.alignItemsStart.alignItems,
    },
    {
      name: "alignItemsEnd",
      value: styling.alignItemsEnd.alignItems,
    },
    {
      name: "justifyContentBetween",
      value: styling.justifyContentBetween.justifyContent,
    },
    {
      name: "justifyContentAround",
      value: styling.justifyContentAround.justifyContent,
    },
  ]);

  return (
    <BAContent
      heading="Flex"
      gridSection={
        <BAGrid
          datasource={flex}
          gridCols={[
            {
              key: "name",
              label: "Name",
            },
            {
              key: "value",
              label: "Value",
            },
          ]}
        />
      }
      codeSection={
        <Box className="px-2" sx={{ height: 700 }}>
          <pre className="bg-dark h-100 text-white p-2 shadow rounded">
            <BACopyButton code={flexCode} />
            <code>
              {flexCode}
            </code>
          </pre>
        </Box>
      }
      mobileSection={
        <BAMobileView>
          <Box className="py-2">
            <Typography className="p-2" variant="h6">
              FlexRow
            </Typography>
            <BAFlexComp flexDirection={styling.flexRow} />
          </Box>

          <Box className="py-2">
            <Typography className="p-2" variant="h6">
              FlexColumn
            </Typography>
            <BAFlexComp flexDirection={styling.flexColumn} />
          </Box>

          <Box className="py-2">
            <Typography className="p-2" variant="h6">
              FlexCenter
            </Typography>
            <BAFlexComp
              flexDirection={styling.flexRow}
              justifyContent={styling.justifyContentCenter}
              alignItems={styling.alignItemsCenter}
            />
          </Box>

          <Box className="py-2">
            <Typography className="p-2" variant="h6">
              FlexWrap
            </Typography>
            <BAFlexComp
              flexDirection={styling.flexRow}
              width={{ width: "40%" }}
              flexWrap={styling.flexWrap}
            />
          </Box>

          <Box className="py-2">
            <Typography className="p-2" variant="h6">
              FlexNoWrap
            </Typography>
            <BAFlexComp
              flexDirection={styling.flexRow}
              width={{ width: "40%" }}
              flexWrap={styling.flexNoWrap}
            />
          </Box>

          <Box className="py-2">
            <Typography className="p-2" variant="h6">
              JustifyContentCenter
            </Typography>
            <BAFlexComp
              flexDirection={styling.flexRow}
              justifyContent={styling.justifyContentCenter}
            />
          </Box>

          <Box className="py-2">
            <Typography className="p-2" variant="h6">
              JustifyContentStart
            </Typography>
            <BAFlexComp
              flexDirection={styling.flexRow}
              justifyContent={styling.justifyContentStart}
            />
          </Box>

          <Box className="py-2">
            <Typography className="p-2" variant="h6">
              JustifyContentEnd
            </Typography>
            <BAFlexComp
              flexDirection={styling.flexRow}
              justifyContent={styling.justifyContentEnd}
            />
          </Box>

          <Box className="py-2">
            <Typography className="p-2" variant="h6">
              AlignItemsCenter
            </Typography>
            <BAFlexComp
              flexDirection={styling.flexRow}
              alignItems={styling.alignItemsCenter}
            />
          </Box>

          <Box className="py-2">
            <Typography className="p-2" variant="h6">
              AlignItemsStart
            </Typography>
            <BAFlexComp
              flexDirection={styling.flexRow}
              alignItems={styling.alignItemsStart}
            />
          </Box>

          <Box className="py-2">
            <Typography className="p-2" variant="h6">
              AlignItemsEnd
            </Typography>
            <BAFlexComp
              flexDirection={styling.flexRow}
              alignItems={styling.alignItemsEnd}
            />
          </Box>

          <Box className="py-2">
            <Typography className="p-2" variant="h6">
              JustifyContentBetween
            </Typography>
            <BAFlexComp
              flexDirection={styling.flexRow}
              justifyContent={styling.justifyContentBetween}
            />
          </Box>

          <Box className="py-2">
            <Typography className="p-2" variant="h6">
              JustifyContentAround
            </Typography>
            <BAFlexComp
              flexDirection={styling.flexRow}
              justifyContent={styling.justifyContentAround}
            />
          </Box>
        </BAMobileView>
      }
    />
  );
}
